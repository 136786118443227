import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 68 42">
		<path
			d="M57.3325,29.3325 L68,40 L68,2.665 C68,1.2 66.8,0 65.3325,0 L30.665,0 C29.2,0 28,1.2 28,2.665 L28,26.6675 C28,28.1325 29.2,29.3325 30.665,29.3325 L57.3325,29.3325 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M26.98,23.06 L24.86,20.938 L15.12,30.68 L10.9,26.46 L8.78,28.58 L15.12,34.92 L26.98,23.06 Z M4,14 L32,14 L32,10 L4,10 L4,14 Z M4,38 L32,38 L32,18 L4,18 L4,38 Z M32,6 L30,6 L30,2 L26,2 L26,6 L10,6 L10,2 L6,2 L6,6 L4,6 C1.8,6 0,7.8 0,10 L0,38 C0,40.198 1.8,42 4,42 L32,42 C34.2,42 36,40.198 36,38 L36,10 C36,7.8 34.2,6 32,6 L32,6 Z"
			fill={styling.colors.iconSecondary}
			fillRule="evenodd"
		/>
	</svg>
);
