import { string, oneOf, object } from "prop-types";
import * as React from "react";

import { EMPTY_OBJ } from "Services/constants/empty";
import styling from "Services/styling";

const arrowStyles = (direction, styles) => {
	const style = {
		display: "inline-block",
		height: 20,
		width: 20,
		transformOrigin: "50% 50%",
		lineHeight: 0,
		...styles,
	};
	switch (direction) {
		case "up":
			return {
				...style,
				transform: "rotate(90deg)",
			};
		case "down":
			return {
				...style,
				transform: "rotate(-90deg)",
			};
		case "right":
			return {
				...style,
				transform: "rotate(180deg)",
			};
		case "left":
		default:
			return style;
	}
};

const hoverFillStyles = (fill) => ({
	...styling.mixins.transition("fill"),
	":hover": {
		fill,
	},
});

const Arrow = ({ direction, fill, fillHover, style }) => (
	<span css={arrowStyles(direction, style)}>
		<svg height="100%" viewBox="0 0 20 20" width="100%">
			<path
				css={hoverFillStyles(fillHover || fill)}
				d="M7.293,2.707 L0.707106781,9.29289322
				C0.316582489,9.68341751 0.316582489,10.3165825 0.707106781,10.7071068
				L7.293,17.293 C7.68346532,17.6834653 8.31653468,17.6834653
				8.707,17.293 L8.707,17.293 C9.09746532,16.9025347 9.09746532,16.2694653
				8.707,15.879 L3.828,11 L19,11 C19.5522847,11 20,10.5522847 20,10 L20,10
				C20,9.44771525 19.5522847,9 19,9 L3.828,9 L8.707,4.121
				C9.09746532,3.73053468 9.09746532,3.09746532 8.707,2.707 L8.707,2.707
				C8.31653468,2.31653468 7.68346532,2.31653468 7.293,2.707 Z"
				fill={fill}
			/>
		</svg>
	</span>
);

Arrow.propTypes = {
	direction: oneOf(["up", "right", "down", "left"]),
	fill: string,
	fillHover: string,
	style: object,
};

Arrow.defaultProps = {
	direction: "right",
	fill: styling.colors.linkDefault,
	style: EMPTY_OBJ,
};

export default Arrow;
