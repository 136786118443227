import dateFnsFormat from "date-fns/format";

import languages from "~/languages";

export const format = (date, formatStr, language) => {
	if (
		typeof language === "undefined" ||
		language === languages.defaultLangKey
	) {
		return dateFnsFormat(date, formatStr);
	}
	return dateFnsFormat(date, formatStr, {
		locale: languages[language],
	});
};
