import { EMPTY_ARR } from "Services/constants/empty";
import styling from "Services/styling";

export default {
	wrapper: {
		position: "absolute",
		top: "100%",
		right: 0,
		width: 710,
		height: 480,
		backgroundColor: styling.colors.backgroundDefault,
		display: "flex",
		...styling.mixins.borderRadiuses(0, 4, 4, 0),
		...styling.mixins.boxShadow(0, 0, 24, 0, styling.colors.boxShadow),
	},
	menus: {
		display: "flex",
		flexGrow: 1,
		flexDirection: "column",
		width: "50%",
	},
	instructors: (isActive) => ({
		height: "50%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: isActive
			? styling.colors.backgroundCircle
			: styling.colors.transparent,
		...styling.mixins.transition("background-color"),
		...styling.mixins.padding(50, 60, 20),
		...styling.mixins.borderRadiuses(0, 4, 0, 0),
		":hover": {
			backgroundColor: styling.colors.backgroundCircle,
		},
	}),
	resortGuests: (isActive) => ({
		height: "50%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: isActive
			? styling.colors.backgroundCircle
			: styling.colors.transparent,
		...styling.mixins.transition("background-color"),
		...styling.mixins.padding(50, 60, 20),
		...styling.mixins.borderRadiuses(0, 0, 4, 0),
		":hover": {
			backgroundColor: styling.colors.backgroundCircle,
		},
	}),
	icon: {
		height: 50,
		width: 50,
		position: "absolute",
		left: 0,
		top: "50%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		...styling.mixins.margin(-25, 0, 0),
	},
	menuHeader: {
		fontSize: "2.4rem",
		lineHeight: "5rem",
		position: "relative",
		...styling.mixins.padding(0, 0, 0, 70),
		...styling.mixins.margin(0, 0, 20),
	},
	menuStrap: {
		fontSize: "1.4rem",
		lineHeight: "1.8rem",
		minHeight: "5.4rem",
		color: styling.colors.textQuadrinary,
	},
	section: {
		position: "relative",
		width: "50%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		backgroundColor: styling.colors.backgroundCircle,
	},
	instructorForm: (isActive) => ({
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		zIndex: isActive ? 2 : 1,
		opacity: isActive ? 1 : 0,
		...styling.mixins.padding(40, 30, 30),
		...styling.mixins.transition("opacity"),
		"> form": {
			height: "100%",
			display: "flex",
			flexDirection: "column",
		},
		select: {
			...styling.mixins.padding(10),
		},
		button: {
			width: 130,
			fontSize: "1.4rem",
			lineHeight: "1.8rem",
			fontWeight: 400,
			...styling.mixins.borderRadius(4),
			...styling.mixins.margin("auto", 0, 0, "auto"),
			...styling.mixins.padding(11, 10),
		},
	}),
	resortGuestForm: (isActive) => ({
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		zIndex: isActive ? 2 : 1,
		opacity: isActive ? 1 : 0,
		...styling.mixins.padding(40, 30, 30),
		...styling.mixins.transition("opacity"),
		"> form": {
			height: "100%",
			display: "flex",
			flexDirection: "column",
		},
		select: {
			...styling.mixins.padding(10),
		},
		input: {
			...styling.mixins.padding(10),
		},
		button: {
			width: 130,
			fontSize: "1.4rem",
			lineHeight: "1.8rem",
			fontWeight: 400,
			...styling.mixins.margin("auto", 0, 0, "auto"),
			...styling.mixins.borderRadius(4),
			...styling.mixins.padding(11, 10),
		},
	}),
	inputLabel: {
		fontSize: "1.6rem",
		lineHeight: "1.9rem",
		color: styling.colors.textDefault,
		whiteSpace: "nowrap",
		...styling.mixins.margin(0, 0, 11),
		"+ div": {
			...styling.mixins.margin(0, 0, 12),
		},
	},
	forgotYourDetails: {
		position: "absolute",
		left: 0,
		bottom: 9,
		color: styling.colors.textQuadrinary,
		fontSize: "1.6rem",
		lineHeight: "2.4rem",
		cursor: "pointer",
		...styling.mixins.border(EMPTY_ARR, EMPTY_ARR, [
			1,
			"dashed",
			styling.colors.textQuadrinary,
		]),
		...styling.mixins.transitionMulti(["border-color"], ["color"]),
		":hover": {
			color: styling.colors.linkInteract,
			borderColor: styling.colors.linkInteract,
		},
	},
};
