export const getClientHeight = () => {
	if (typeof window === "undefined") {
		return 0;
	}
	if (window.document) {
		if (
			window.document.documentElement &&
			window.document.documentElement.clientHeight
		) {
			return window.document.documentElement.clientHeight;
		}
		if (window.document.body && window.document.body.clientHeight) {
			return window.document.body.clientHeight;
		}
	}
	return 0;
};

export const getClientWidth = () => {
	if (typeof window === "undefined") {
		return 0;
	}
	if (window.document) {
		if (
			window.document.documentElement &&
			window.document.documentElement.clientWidth
		) {
			return window.document.documentElement.clientWidth;
		}
		if (window.document.body && window.document.body.clientWidth) {
			return window.document.body.clientWidth;
		}
	}
	return 0;
};

export const getScrollHeight = () => {
	if (typeof window === "undefined") {
		return 0;
	}
	if (window.document) {
		if (
			window.document.documentElement &&
			window.document.documentElement.scrollHeight
		) {
			return window.document.documentElement.scrollHeight;
		}
		if (window.document.body && window.document.body.scrollHeight) {
			return window.document.body.scrollHeight;
		}
	}
	return 0;
};

export const getScrollTop = () => {
	if (typeof window === "undefined") {
		return 0;
	}
	if (window.document) {
		if (
			window.document.documentElement &&
			window.document.documentElement.scrollTop
		) {
			return window.document.documentElement.scrollTop;
		}
		if (window.document.body && window.document.body.scrollTop) {
			return window.document.body.scrollTop;
		}
	}
	return 0;
};

export const getElementTop = (el, ignoreFirst = false) => {
	let yPos = ignoreFirst ? 0 : el.offsetTop;
	let tempEl = el.offsetParent;

	while (tempEl !== null) {
		yPos += tempEl.offsetTop;
		tempEl = tempEl.offsetParent;
	}

	return yPos;
};
