import styling from "Services/styling";

export default {
	icon: {
		height: 40,
		width: 40,
		...styling.mixins.margin(20, "auto", 35),
		[styling.sizes.breakpoints.small]: {
			...styling.mixins.margin(0, "auto", 35),
		},
	},
	title: {
		fontSize: "4rem",
		lineHeight: "4.8rem",
		fontWeight: 700,
		textAlign: "center",
		...styling.mixins.margin(0, 0, 48),
	},
};
