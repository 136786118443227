import addMonths from "date-fns/addMonths";
import setDay from "date-fns/setDay";
import formatDateFns from "date-fns/format";
import React from "react";
import { string, func } from "prop-types";

import Arrow from "Components/Arrow";
import { format } from "Services/helper-utils/date";
import Styles from "./styles.css";

const generateDaysArray = (monthIndex, fullYear) => {
	// find number of days in the month
	const daysInMonth = new Date(fullYear, monthIndex + 1, 0).getDate();
	// find day of the week for first day
	// 1 = Monday, 0 = Sunday
	const dayOfWeekOfTheFirst =
		+formatDateFns(new Date(fullYear, monthIndex, 1), "i") % 7;
	// generate arrays for each week
	const numberOfWeeks = Math.ceil((daysInMonth + dayOfWeekOfTheFirst) / 7);
	// days array with padding at the start
	const daysArray = [...Array(dayOfWeekOfTheFirst)].fill(null).concat(
		[...Array(daysInMonth)].map((nothing, i) => {
			const date = new Date(fullYear, monthIndex, i + 1);
			return {
				date: date.getDate(),
				string: formatDateFns(date, "yyyy-MM-dd"),
			};
		})
	);
	return daysArray.concat([
		...Array(numberOfWeeks * 7 - daysArray.length).fill(null),
	]);
	// chunk into 7 day blocks
	// return [...Array(numberOfWeeks)].map((nothing, i) =>
	// 	daysArray.slice(i * 7, i * 7 + 7)
	// );
};

const generateWeekDays = (lang) => {
	const today = new Date();
	return [...Array(7)].map((nothing, i) =>
		format(setDay(today, i), "iiiiii", lang)
	);
};

class DatePicker extends React.Component {
	today;
	static propTypes = {
		handleOnChange: func,
		max: string,
		min: string,
		value: string,
	};
	static getDerivedStateFromProps(props, state) {
		if (props.value !== state.value) {
			return {
				value: props.value,
				valueDate: new Date(props.value),
			};
		}
		return null;
	}
	constructor(props) {
		super(props);
		const todayString = formatDateFns(new Date(), "yyyy-MM-dd");
		const valueDate = new Date(props.value);
		const visualMonth = valueDate.getMonth();
		const visualYear = valueDate.getFullYear();
		this.state = {
			visualDate: valueDate,
			valueDate,
			value: props.value,
			todayString,
			daysArray: generateDaysArray(visualMonth, visualYear),
			weekdays: generateWeekDays(props.lang),
		};
	}
	moveVisualMonth = (direction) => {
		this.setState((prevState) => {
			const visualDate = addMonths(prevState.visualDate, direction);
			const visualMonth = visualDate.getMonth();
			const visualYear = visualDate.getFullYear();
			return {
				visualDate,
				daysArray: generateDaysArray(visualMonth, visualYear),
			};
		});
	};
	render() {
		const { handleOnChange, lang, max, min, value } = this.props;
		const { daysArray, todayString, visualDate, weekdays } = this.state;
		return (
			<>
				<div css={Styles.header}>
					<div css={Styles.arrowLeft} onClick={() => this.moveVisualMonth(-1)}>
						<Arrow direction="left" />
					</div>
					<span css={Styles.monthYear}>
						{format(visualDate, "MMMM yyyy", lang)}
					</span>
					<div css={Styles.arrowRight} onClick={() => this.moveVisualMonth(1)}>
						<Arrow direction="right" />
					</div>
				</div>
				<div css={Styles.weekdays}>
					{weekdays.map((dow, i) => (
						<div key={i} css={Styles.weekday}>
							{dow}
						</div>
					))}
				</div>
				<div css={Styles.days}>
					{daysArray.map((day, index) => {
						const isSelected = day !== null && day.string === value;
						const isAfterMax =
							day !== null && typeof max !== "undefined" && day.string > max;
						const isBeforeMin =
							day !== null && typeof min !== "undefined" && day.string < min;
						const isToday = day !== null && todayString === day.string;
						return (
							<div
								key={index}
								css={Styles.day(
									isSelected,
									isToday,
									isAfterMax || isBeforeMin,
									day === null
								)}
								onClick={() => day && handleOnChange(day.string)}>
								{day && day.date}
							</div>
						);
					})}
				</div>
			</>
		);
	}
}

export default DatePicker;
