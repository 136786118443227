import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 38 24">
		<path
			d="M14.3334861,21 L14.3334861,24 L37.6667806,24 L37.6667806,21 C37.6667806,17.0057143 29.9001268,15 26.0001333,15 C22.1001398,15 14.3334861,17.0057143 14.3334861,21 Z M31.8334569,6 C31.8334569,2.69314286 29.2167946,-4.54747351e-13 26.0001333,-4.54747351e-13 C22.783472,-4.54747351e-13 20.1668097,2.69314286 20.1668097,6 C20.1668097,9.30857143 22.783472,12 26.0001333,12 C29.2167946,12 31.8334569,9.30857143 31.8334569,6 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M4.23347961,20.5714286 C5.63347728,19.5771429 9.01680497,18.4285714 12.0001333,18.4285714 C14.9834617,18.4285714 18.3667894,19.5771429 19.7667871,20.5714286 L4.23347961,20.5714286 Z M12.0001333,15 C8.10013983,15 0.333486111,17.0057143 0.333486111,21 L0.333486111,24 L23.6667806,24 L23.6667806,21 C23.6667806,17.0057143 15.9001268,15 12.0001333,15 L12.0001333,15 Z M12.0001375,3.42857143 C13.3834644,3.42857143 14.5001375,4.57714286 14.5001375,6 C14.5001375,7.42457143 13.3834644,8.57142857 12.0001375,8.57142857 C10.6168023,8.57142857 9.5001375,7.42457143 9.5001375,6 C9.5001375,4.57714286 10.6168023,3.42857143 12.0001375,3.42857143 L12.0001375,3.42857143 Z M12.0001333,12 C15.2167946,12 17.8334569,9.30857143 17.8334569,6 C17.8334569,2.69314286 15.2167946,-4.54747351e-13 12.0001333,-4.54747351e-13 C8.78347203,-4.54747351e-13 6.16680972,2.69314286 6.16680972,6 C6.16680972,9.30857143 8.78347203,12 12.0001333,12 L12.0001333,12 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
