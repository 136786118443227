import format from "date-fns/format";
import { bool, func, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { HeaderPropTypes, LoginResortsPropTypes } from "~/types";
import Form from "Components/Form";
import { Mortars, ShadowShadowAlt } from "Components/Icons";
import InputDate from "Components/InputDate";
import InputSelect from "Components/InputSelect";
import InputTag from "Components/InputTag";
import { EMPTY_ARR } from "Services/constants/empty";
import flaikRequest from "Services/flaik-request";
import ReliveYourDaySuccess from "./elements/ReliveYourDaySuccess";
import Styles from "./styles.css";

class SignInMenu extends React.Component {
	isMounted;
	todayDate;
	static propTypes = {
		hasFormResponse: bool,
		header: HeaderPropTypes,
		lang: string,
		loginResorts: LoginResortsPropTypes,
		openModal: func,
		reset: func,
	};
	constructor(props) {
		super(props);
		this.todayDate = new Date().setHours(0, 0, 0, 0);
		this.state = {
			menuHovered: "instructors",
			tagValue: "",
			tagIsValid: false,
			lessonDateValue: format(this.todayDate, "yyyy-MM-dd"),
			lessonDateIsValid: true,
			resortValue: "",
			resortIsValid: false,
			guestResortValue: "",
			guestResortIsValid: false,
			loginItems: props.loginResorts.reduce((items, node) => {
				if (node.node) {
					return items.concat({
						title: node.node.title,
						value: node.node.slug,
					});
				}
				return items;
			}, EMPTY_ARR),
		};
	}
	componentDidMount() {
		this.isMounted = true;
	}
	componentWillUnmount() {
		this.isMounted = false;
	}
	resetAllFields = () => {
		if (this.isMounted) {
			this.setState({
				tagValue: "",
				tagIsValid: false,
				lessonDateValue: format(this.todayDate, "yyyy-MM-dd"),
				lessonDateIsValid: true,
			});
		}
	};
	submitReliveForm = () => {
		const { tagValue, lessonDateValue, guestResortValue } = this.state;
		// relive my day ajax
		return flaikRequest({
			data: {
				flaikReliveYourDay: {
					tagId: tagValue,
					resort: guestResortValue,
					lessonDate: lessonDateValue,
				},
			},
		});
	};
	submitResortForm = () => {
		const { resortValue, resortIsValid } = this.state;
		if (resortIsValid && typeof window !== "undefined") {
			window.location.href = `https://${resortValue}.flaik.com/`;
		}
	};
	setMenuHover = (menu) => {
		this.setState({
			menuHovered: menu,
		});
	};
	updateFieldValue = (fieldName, fieldValue, fieldIsValid) => {
		const { hasFormResponse, reset } = this.props;
		if (hasFormResponse) {
			reset();
		}
		this.setState({
			[`${fieldName}Value`]: fieldValue,
			[`${fieldName}IsValid`]: fieldIsValid,
		});
	};
	render() {
		const { header, lang, openModal } = this.props;
		const {
			menuHovered,
			resortIsValid,
			loginItems,
			resortValue,
			tagValue,
			tagIsValid,
			lessonDateValue,
			lessonDateIsValid,
			guestResortIsValid,
			guestResortValue,
		} = this.state;
		return (
			<div css={Styles.wrapper}>
				<div css={Styles.menus}>
					<div
						css={Styles.instructors(menuHovered === "instructors")}
						onMouseEnter={() => this.setMenuHover("instructors")}
						onClick={() => this.setMenuHover("instructors")}>
						<div css={Styles.menuHeader}>
							<div css={Styles.icon}>
								<Mortars />
							</div>
							{header.instructorsMenu}
						</div>
						<div css={Styles.menuStrap}>{header.instructorsStrap}</div>
					</div>
					<div
						css={Styles.resortGuests(menuHovered === "resortGuests")}
						onMouseEnter={() => this.setMenuHover("resortGuests")}
						onClick={() => this.setMenuHover("resortGuests")}>
						<div css={Styles.menuHeader}>
							<div css={Styles.icon}>
								<ShadowShadowAlt />
							</div>
							{header.resortGuestsMenu}
						</div>
						<div css={Styles.menuStrap}>{header.resortGuestsStrap}</div>
					</div>
				</div>
				<div css={Styles.section}>
					<div css={Styles.instructorForm(menuHovered === "instructors")}>
						<Form
							instructorLogin={this.submitResortForm}
							isValid={resortIsValid}
							renderFields={({ isSubmitting }) => (
								<>
									<div css={Styles.inputLabel}>
										Select your resort from the dropdown
									</div>
									<InputSelect
										isDisabled={isSubmitting}
										isRequired
										isValid={resortIsValid}
										items={loginItems}
										label="Select your resort"
										name="resort"
										onChange={this.updateFieldValue}
										value={resortValue}
									/>
								</>
							)}
							submitText={header.instructorsButton}
							type={"instructorLogin"}
						/>
					</div>
					<div css={Styles.resortGuestForm(menuHovered === "resortGuests")}>
						<Form
							reliveMyDay={this.submitReliveForm}
							isValid={tagIsValid && lessonDateIsValid && guestResortIsValid}
							renderFields={({ isSubmitting }) => (
								<>
									<div css={Styles.inputLabel}>
										Select a resort from the dropdown
									</div>
									<InputSelect
										isDisabled={isSubmitting}
										isRequired
										isValid={guestResortIsValid}
										items={loginItems}
										label="Select a resort"
										name="guestResort"
										onChange={this.updateFieldValue}
										value={guestResortValue}
									/>
									<div css={Styles.inputLabel}>Enter your lesson Tag ID</div>
									<InputTag
										isDisabled={isSubmitting}
										isRequired
										isValid={tagIsValid}
										label="eg SCART-1"
										name="tag"
										onChange={this.updateFieldValue}
										value={tagValue}
									/>
									<div css={Styles.inputLabel}>Select your lesson date</div>
									<InputDate
										isDisabled={isSubmitting}
										isRequired
										isValid={lessonDateIsValid}
										label="Lesson date"
										lang={lang}
										max={format(this.todayDate, "yyyy-MM-dd")}
										name="lessonDate"
										onChange={this.updateFieldValue}
										value={lessonDateValue}
									/>
									<div css={Styles.forgotYourDetails} onClick={openModal}>
										{header.havingTroubleMenu}
									</div>
								</>
							)}
							submitText={header.resortGuestsButton}
							successMessage={() => <ReliveYourDaySuccess />}
							type={"reliveMyDay"}
						/>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	hasFormResponse: state.formResponse.key !== "",
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	openModal: () =>
		dispatch({
			type: "MODAL_KEY_SET",
			payload: {
				modalKey: "forgot-your-details",
				modalProps: {
					body: ownProps.header.havingTroubleBody,
					button: ownProps.header.havingTroubleButton,
					lang: ownProps.lang,
					loginResorts: ownProps.loginResorts,
					menu: ownProps.header.havingTroubleStrap,
					thankYou: ownProps.header.havingTroubleThankYou,
				},
			},
		}),
	reset: () =>
		dispatch({
			type: "FORM_SUBMIT_RESET",
			payload: {
				formKey: null,
			},
		}),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SignInMenu);
