import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 76 36">
		<path
			d="M68.02,10 L54,10 L54,14 L68.02,14 L68.02,20 L76,12 L68.02,4 L68.02,10 Z M40,24 L47.978,32 L47.978,26 L62,26 L62,22 L47.978,22 L47.978,16.002 L40,24 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M35.409271,15.06 C35.129271,15.42 34.529271,16 33.529271,16 C32.309271,16 31.249271,15.018 31.109271,13.718 L29.949271,4 L33.809271,3.98 L35.909271,12.718 C36.109271,13.56 35.929271,14.42 35.409271,15.06 L35.409271,15.06 Z M33.989271,32 L5.98927105,32 L5.98927105,19.938 C6.14927105,19.958 6.28927105,20 6.44927105,20 C8.18927105,20 9.76927105,19.278 10.929271,18.1 C12.129271,19.298 13.729271,20 15.549271,20 C17.289271,20 18.849271,19.278 20.009271,18.14 C21.189271,19.278 22.789271,20 24.589271,20 C26.269271,20 27.869271,19.298 29.069271,18.1 C30.229271,19.278 31.809271,20 33.549271,20 C33.709271,20 33.849271,19.958 34.009271,19.938 L34.009271,32 L33.989271,32 Z M4.56927105,15.06 C4.04927105,14.4 3.86927105,13.538 4.06927105,12.718 L6.08927105,4 L10.029271,4 L8.86927105,13.718 C8.70927105,15.018 7.66927105,16 6.44927105,16 C5.44927105,16 4.84927105,15.42 4.56927105,15.06 L4.56927105,15.06 Z M12.969271,13.038 L14.069271,4 L17.989271,4 L17.989271,13.378 C17.989271,14.82 16.889271,16 15.409271,16 C14.729271,16 14.109271,15.698 13.629271,15.18 C13.129271,14.6 12.889271,13.82 12.969271,13.038 L12.969271,13.038 Z M21.989271,4 L25.909271,4 L26.989271,13.038 C27.089271,13.82 26.849271,14.6 26.329271,15.18 C25.889271,15.698 25.249271,16 24.429271,16 C23.089271,16 21.989271,14.82 21.989271,13.378 L21.989271,4 Z M39.789271,11.778 L37.689271,3.038 C37.249271,1.24 35.689271,0 33.869271,0 L6.08927105,0 C4.28927105,0 2.70927105,1.26 2.28927105,3.038 L0.189271048,11.778 C-0.290728952,13.82 0.149271048,15.9 1.42927105,17.538 C1.58927105,17.76 1.80927105,17.92 1.98927105,18.118 L1.98927105,32 C1.98927105,34.198 3.78927105,36 5.98927105,36 L33.989271,36 C36.189271,36 37.989271,34.198 37.989271,32 L37.989271,18.118 C38.169271,17.938 38.389271,17.76 38.549271,17.56 C39.829271,15.92 40.289271,13.82 39.789271,11.778 L39.789271,11.778 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
