import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 65 36">
		<path
			d="M49.122,22.95 L44.88,18.708 L37.808,25.778 L34.98,22.95 L44.88,13.052 L49.122,17.294 L56.194,10.224 L59.022,13.052 L49.122,22.95 Z M61,0 L33,0 C30.8,0 29,1.802 29,4 L29,32 C29,34.2 30.8,36 33,36 L61,36 C63.202,36 65,34.2 65,32 L65,4 C65,1.802 63.202,0 61,0 L61,0 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M31.42,19.362 C32.94,18.5 34,16.882 34,15 C34,12.24 31.76,10 29,10 C26.24,10 24,12.24 24,15 C24,16.882 25.06,18.5 26.58,19.362 C27.3,19.76 28.12,20 29,20 C29.88,20 30.7,19.76 31.42,19.362 M29,23 C25.32,23 18,25.022 18,29 L18,32 L40,32 L40,29 C40,25.022 32.68,23 29,23 M14.1,28 L5.54,28 C7.52,27 10.94,26 14,26 C14.22,26 14.46,26.022 14.68,26.022 C15.36,24.562 16.54,23.362 17.96,22.402 C16.5,22.142 15.12,22 14,22 C9.32,22 0,24.34 0,29 L0,32 L14,32 L14,29 C14,28.66 14.04,28.32 14.1,28 M14,8 C15.66,8 17,9.34 17,11 C17,12.66 15.66,14 14,14 C12.34,14 11,12.66 11,11 C11,9.34 12.34,8 14,8 M14,18 C17.86,18 21,14.862 21,11 C21,7.142 17.86,4 14,4 C10.14,4 7,7.142 7,11 C7,14.862 10.14,18 14,18"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
