import { bool, func, string } from "prop-types";
import * as React from "react";

import Styles from "./styles.css";

class InputHoneypot extends React.Component {
	static propTypes = {
		isDisabled: bool,
		label: string,
		name: string,
		onChange: func,
		value: string,
	};
	handleOnChange = (e) => {
		const { isDisabled, name, onChange } = this.props;
		if (isDisabled) return;
		onChange(name, e.target.value, true);
	};
	render() {
		const { isDisabled, label, name, value } = this.props;
		return (
			<div css={Styles.wrapper(isDisabled)}>
				{isDisabled && <div css={Styles.inputCover} />}
				<input
					css={Styles.input}
					disabled={isDisabled}
					name={name}
					onChange={this.handleOnChange}
					placeholder={label}
					type="text"
					value={value}
				/>
			</div>
		);
	}
}

export default InputHoneypot;
