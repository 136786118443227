import StyleMixins from "./style-mixins";

export default {
	absInner: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		height: "auto",
		width: "auto",
		overflow: "hidden",
		maxHeight: "100%",
	},
	disabled: {
		cursor: "not-allowed",
		opacity: 0.2,
	},
	imageResponsive: {
		maxWidth: "100%",
		minWidth: "100%",
	},
	listUnstyled: {
		listStyleType: "none",
	},
	maxRounded: {
		overflow: "hidden",
		...StyleMixins.borderRadius("100000rem"), // hack because 100% makes ovals instead of max rounded corner rectangles
	},
	textOverflow: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		OTextOverflow: "ellipsis",
		textOverflow: "ellipsis",
	},
};
