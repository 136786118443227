import styling from "Services/styling";

const app = {
	...styling.reset,
	html: {
		fontSize: "62.5%", // reset base font size to 10px for use of em's
		WebkitFontSmoothing: "antialiased",
		textRendering: "optimizeLegibility",
		height: "100%",
	},
	body: {
		fontFamily: styling.fontFamily,
		minWidth: styling.sizes.wMinWidth,
		minHeight: styling.sizes.wMinHeight,
		color: styling.colors.textDefault,
		backgroundColor: styling.colors.backgroundDefault,
		fontSize: 10,
		lineHeight: "1.4em",
		overflowX: "hidden",
		overflowY: "scroll",
		display: "flex",
		flexDirection: "column",
	},
	"body.modal-open": {
		overflow: "hidden",
		WebkitOverflowScrolling: "auto",
	},
	"*": {
		boxSizing: "border-box",
	},
};
const Styles = {
	app,
	mainWrapper: {
		overflow: "hidden",
		...styling.mixins.padding(82, 0, 0),
	},
	main: {
		display: "flex",
		flexDirection: "column",
		maxWidth: styling.sizes.wrapperMinWidth,
		...styling.mixins.padding(0, 40),
		...styling.mixins.margin(0, "auto"),
		[styling.sizes.breakpoints.medium]: {
			...styling.mixins.padding(0, 70),
		},
	},
};

export default Styles;
