import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 67 40">
		<path
			d="M38.44,17.06 L39.25,15.35 C40.48,12.71 43.12,11 46,11 C49.69,11 52.87,13.76 53.38,17.42 L53.89,20 L56.5,20 C58.99,20 61,22.01 61,24.5 C61,26.99 58.99,29 56.5,29 L37,29 C33.7,29 31,26.3 31,23 C31,19.97 33.22,17.45 36.22,17.06 L38.44,17.06 Z M56.5,35 C62.29,35 67,30.29 67,24.5 C67,19.52 63.52,15.35 58.78,14.27 C56.98,8.84 51.85,5 46,5 C41.41,5 37.12,7.4 34.66,11.24 C29.08,12.32 25,17.21 25,23 C25,29.63 30.37,35 37,35 L56.5,35 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M28,36 L4,36 L4,4 L18,4 L18,14 L28,14 L28,36 Z M20,0 L4,0 C1.8,0 0,1.8 0,4 L0,36 C0,38.2 1.78,40 3.98,40 L28,40 C30.2,40 32,38.2 32,36 L32,12 L20,0 Z M8,24 L24,24 L24,20 L8,20 L8,24 Z M8,32 L24,32 L24,28 L8,28 L8,32 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
