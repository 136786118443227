import axios from "axios";

import { NOOP } from "Services/constants/empty";
import logger from "Services/logger";

const flaikAxiosConfig = {
	baseURL: "https://operations.flaik.com/marketingapi",
	headers: {
		"Content-Type": "application/json;charset=utf-8",
		"Access-Control-Allow-Origin": "*",
	},
	timeout: 30000,
};

const flaikRequestDefault = {
	method: "post",
};

const flaikAxios = axios.create(flaikAxiosConfig);

// Add a response interceptor
flaikAxios.interceptors.response.use(
	(response) => {
		// Do something with response data
		logger("log", "Response received: ", response);
		if (
			response &&
			response.config &&
			response.config.cancelTimeout &&
			typeof window !== "undefined"
		) {
			window.clearTimeout(response.config.cancelTimeout);
		}
		if (response.data && typeof window !== "undefined") {
			return response;
		}
		logger("error", "Failed request ", response.data.msg);
		return Promise.reject(
			new Error(`Request failed with server response: ${response.data.msg}`)
		);
	},
	(error) => {
		if (typeof window !== "undefined") {
			if (axios.isCancel(error)) {
				logger("warn", "Request canceled  ", error.message);
			} else if (error.response) {
				if (error && error.config && error.config.cancelTimeout) {
					window.clearTimeout(error.config.cancelTimeout);
				}
				// Do something with response error
				const requestUrl = error.config.url.replace(
					window.location.origin + flaikAxiosConfig.baseURL,
					""
				);
				window.console.error(
					"Failed request to ",
					`${requestUrl} : ${error.response.status} - ${error.response.statusText}`
				);
			} else {
				if (error && error.config && error.config.cancelTimeout) {
					window.clearTimeout(error.config.cancelTimeout);
				}
				if (error.message) {
					window.console.error("Failed request ", error.message);
				} else {
					window.console.error("Failed request ", JSON.stringify(error));
				}
			}
		}
		return Promise.reject(error);
	}
);
const flaikRequest = async (config) => {
	const { CancelToken } = axios;
	const source = CancelToken.source();
	const cancelTimeout =
		typeof window !== "undefined"
			? window.setTimeout(() => {
					source.cancel(
						`Request took longer than the timeout of ${flaikAxiosConfig.timeout}ms`
					);
			  }, flaikAxiosConfig.timeout)
			: NOOP;
	const requestTime = Date.now();
	return flaikAxios({
		cancelToken: source.token,
		cancelTimeout,
		...flaikRequestDefault,
		...config,
		requestTime,
	}).then(async ({ data }) => {
		return data;
	});
};

export default flaikRequest;
