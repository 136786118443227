import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 70 42">
		<path
			d="M38,37 L66,37 L66,9 L38,9 L38,37 Z M52,4.5 C52.82,4.5 53.5,5.18 53.5,6 C53.5,6.82 52.82,7.5 52,7.5 C51.18,7.5 50.5,6.82 50.5,6 C50.5,5.18 51.18,4.5 52,4.5 L52,4.5 Z M66,5 L57.64,5 C56.8,2.68 54.6,1 52,1 C49.4,1 47.2,2.68 46.36,5 L38,5 C37.72,5 37.46,5.02 37.2,5.08 C36.42,5.24 35.72,5.64 35.18,6.18 C34.82,6.54 34.52,6.98 34.32,7.46 C34.12,7.94 34,8.44 34,9 L34,37 C34,37.54 34.12,38.08 34.32,38.56 C34.52,39.04 34.82,39.46 35.18,39.84 C35.72,40.38 36.42,40.78 37.2,40.94 C37.46,40.98 37.72,41 38,41 L66,41 C68.2,41 70,39.2 70,37 L70,9 C70,6.8 68.2,5 66,5 L66,5 Z M42,17 L62,17 L62,13 L42,13 L42,17 Z M42,25 L62,25 L62,21 L42,21 L42,25 Z M42,33 L56,33 L56,29 L42,29 L42,33 Z"
			fill={styling.colors.primary}
			fillRule="evenodd"
		/>
		<path
			d="M11.52,8.60088787 L7.94,4.99011886 L5.12,7.8169841 L8.72,11.4056996 L11.52,8.60088787 Z M0,24.0584276 L6,24.0584276 L6,20.0486897 L0,20.0486897 L0,24.0584276 Z M22,13.9318345 C26.42,13.9318345 30,17.5205499 30,21.9513103 C30,24.8002291 28.46,27.4446513 26,28.8901618 L24,30.050981 L24,37.9902621 L20,37.9902621 L20,30.050981 L18,28.8901618 C15.52,27.4446513 14,24.8002291 14,21.9513103 C14,17.5205499 17.58,13.9318345 22,13.9318345 L22,13.9318345 Z M16,32.3585851 L16,42 L28,42 L28,32.3585851 C31.58,30.2735214 34,26.4041243 34,21.9513103 C34,15.3171989 28.62,9.92209652 22,9.92209652 C15.38,9.92209652 10,15.3171989 10,21.9513103 C10,26.4041243 12.42,30.2735214 16,32.3585851 L16,32.3585851 Z M38,24.0584276 L44,24.0584276 L44,20.0486897 L38,20.0486897 L38,24.0584276 Z M20,6.0146069 L24,6.0146069 L24,0 L20,0 L20,6.0146069 Z M38.9,7.83903766 L36.08,5.01217242 L32.5,8.62093656 L35.32,11.4478018 L38.9,7.83903766 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
