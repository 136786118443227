import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 71 40">
		<path
			d="M50.9994,4.24847311 C60.9394,4.24847311 68.9994,12.2548289 68.9994,22.1242366 C68.9994,31.9976166 60.9394,40 50.9994,40 C41.0394,40 32.9994,31.9976166 32.9994,22.1242366 C32.9994,12.2548289 41.0594,4.24847311 50.9994,4.24847311 Z M51.9994,12.1932569 L48.9994,12.1932569 L48.9994,24.1104325 L58.4994,29.7710909 L59.9994,27.3300561 L51.9994,22.6207855 L51.9994,12.1932569 Z M61.7994,0.000198619593 L70.9994,7.66691494 L68.4194,10.7038085 L59.2194,3.03907841 L61.7994,0.000198619593 Z M40.2,-8.01136935e-13 L42.76,3.03887979 L33.58,10.6857341 L31,7.64486817 L40.2,-8.01136935e-13 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M20,20 L4,10 L36,10 L20,20 Z M36,34 L4,34 L4,14 L20,24 L36,14 L36,34 Z M36,6 L4,6 C1.8,6 0.02,7.8 0.02,10 L0,34 C0,36.202 1.8,38 4,38 L36,38 C38.2,38 40,36.202 40,34 L40,10 C40,7.8 38.2,6 36,6 L36,6 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
