import styling from "Services/styling";

export default {
	modalBackdrop: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		opacity: 0.6,
		zIndex: styling.zIndex.modalBG,
		backgroundColor: styling.colors.modalBackdropBackground,
	},
	modalWrapper: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: styling.zIndex.modal,
		minWidth: styling.sizes.wMinWidth,
		WebkitOverflowScrolling: "touch",
		overflowY: "scroll",
		cursor: "pointer",
		[styling.sizes.breakpoints.small]: {
			overflow: "auto",
		},
	},
	modalInner: {
		position: "relative",
		maxWidth: 700,
		width: "100%",
		minHeight: "100%",
		WebkitOverflowScrolling: "touch",
		overflowY: "scroll",
		backgroundColor: styling.colors.modalBackground,
		display: "flex",
		cursor: "default",
		flexDirection: "column",
		...styling.mixins.margin(0, "auto"),
		...styling.mixins.padding(30, 40),
		[styling.sizes.breakpoints.small]: {
			minHeight: 0,
			...styling.mixins.borderRadius(4),
			...styling.mixins.padding(80),
			...styling.mixins.margin(76, "auto"),
		},
	},
	modalContent: {
		position: "relative",
		zIndex: 1,
		flexGrow: 1,
	},
	closeButton: {
		position: "absolute",
		top: 30,
		right: 40,
		height: 20,
		width: 20,
		color: styling.colors.inputBorder,
		zIndex: 2,
		fontSize: "2rem",
		cursor: "pointer",
		":hover use": {
			fill: styling.colors.textTertiary,
			...styling.mixins.transition("fill"),
		},
		[styling.sizes.breakpoints.small]: {
			top: 30,
			right: 30,
		},
	},
};
