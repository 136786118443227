import { EMPTY_OBJ } from "Services/constants/empty";

const convertNumberToPixel = (num) => {
	if (Number.isFinite(num)) {
		return `${num}px`;
	}
	return num;
};

const convertNumberToSecond = (num) => {
	if (Number.isFinite(num)) {
		return `${num}s`;
	}
	return num;
};

const borderEntry = (thickness = 0, style = "solid", color = "transparent") =>
	`${convertNumberToPixel(thickness)} ${style} ${color}`;

const appearance = (value = "none") => ({
	WebkitAppearance: value,
	MozAppearance: value,
	MsAppearance: value,
	OAppearance: value,
	appearance: value,
});

const animation = (
	name = "spin",
	animationSpeed = 0.2,
	progression = "linear",
	repeat = "infinite",
	delay = 0
) => ({
	WebkitAnimation: `${name} ${convertNumberToSecond(
		animationSpeed
	)} ${progression} ${convertNumberToSecond(delay)} ${repeat}`,
	MozAnimation: `${name} ${convertNumberToSecond(
		animationSpeed
	)} ${progression} ${convertNumberToSecond(delay)} ${repeat}`,
	animation: `${name} ${convertNumberToSecond(
		animationSpeed
	)} ${progression} ${convertNumberToSecond(delay)} ${repeat}`,
});

const backfaceVisibility = (visibility) => ({
	WebkitBackfaceVisibility: visibility,
	MozBackfaceVisibility: visibility,
	backfaceVisibility: visibility,
});

const border = (...args) => {
	switch (args.length) {
		case 4:
			return {
				borderTop: borderEntry(...args[0]),
				borderRight: borderEntry(...args[1]),
				borderBottom: borderEntry(...args[2]),
				borderLeft: borderEntry(...args[3]),
			};
		case 3:
			return {
				borderTop: borderEntry(...args[0]),
				borderRight: borderEntry(...args[1]),
				borderBottom: borderEntry(...args[2]),
				borderLeft: borderEntry(...args[1]),
			};
		case 2:
			return {
				borderTop: borderEntry(...args[0]),
				borderRight: borderEntry(...args[1]),
				borderBottom: borderEntry(...args[0]),
				borderLeft: borderEntry(...args[1]),
			};
		case 1:
			return {
				borderTop: borderEntry(...args[0]),
				borderRight: borderEntry(...args[0]),
				borderBottom: borderEntry(...args[0]),
				borderLeft: borderEntry(...args[0]),
			};
		default:
			return EMPTY_OBJ;
	}
};

const borderRadiuses = (tr = 0, br = 0, bl = 0, tl = 0) => ({
	borderTopRightRadius: convertNumberToPixel(tr),
	borderBottomRightRadius: convertNumberToPixel(br),
	borderBottomLeftRadius: convertNumberToPixel(bl),
	borderTopLeftRadius: convertNumberToPixel(tl),
});

const borderRadius = (x = 0) => borderRadiuses(x, x, x, x);

const boxShadow = (
	x = 0,
	y = 0,
	blur = 4,
	spread = 0,
	color = "rgba(0, 0, 0, 0.1)"
) => ({
	boxShadow: `${convertNumberToPixel(x)} ${convertNumberToPixel(
		y
	)} ${convertNumberToPixel(blur)} ${convertNumberToPixel(spread)} ${color}`,
});

const padding = (...args) => {
	switch (args.length) {
		case 4:
			return {
				paddingTop: convertNumberToPixel(args[0]),
				paddingRight: convertNumberToPixel(args[1]),
				paddingBottom: convertNumberToPixel(args[2]),
				paddingLeft: convertNumberToPixel(args[3]),
			};
		case 3:
			return {
				paddingTop: convertNumberToPixel(args[0]),
				paddingRight: convertNumberToPixel(args[1]),
				paddingBottom: convertNumberToPixel(args[2]),
				paddingLeft: convertNumberToPixel(args[1]),
			};
		case 2:
			return {
				paddingTop: convertNumberToPixel(args[0]),
				paddingRight: convertNumberToPixel(args[1]),
				paddingBottom: convertNumberToPixel(args[0]),
				paddingLeft: convertNumberToPixel(args[1]),
			};
		case 1:
			return {
				paddingTop: convertNumberToPixel(args[0]),
				paddingRight: convertNumberToPixel(args[0]),
				paddingBottom: convertNumberToPixel(args[0]),
				paddingLeft: convertNumberToPixel(args[0]),
			};
		default:
			return EMPTY_OBJ;
	}
};

const perspective = (num) => ({
	WebkitPerspective: convertNumberToPixel(num),
	MozPerspective: convertNumberToPixel(num),
	perspective: convertNumberToPixel(num),
});

const placeholder = (style, preSelector = "") => ({
	[`${preSelector}::-webkit-input-placeholder`]: style /* Chrome/Opera/Safari */,
	[`${preSelector}::-moz-placeholder`]: style /* Firefox 19+ */,
	[`${preSelector}:-ms-input-placeholder`]: style /* IE 10+ */,
	[`${preSelector}:-moz-placeholder`]: style /* Firefox 18- */,
});

const margin = (...args) => {
	switch (args.length) {
		case 4:
			return {
				marginTop: convertNumberToPixel(args[0]),
				marginRight: convertNumberToPixel(args[1]),
				marginBottom: convertNumberToPixel(args[2]),
				marginLeft: convertNumberToPixel(args[3]),
			};
		case 3:
			return {
				marginTop: convertNumberToPixel(args[0]),
				marginRight: convertNumberToPixel(args[1]),
				marginBottom: convertNumberToPixel(args[2]),
				marginLeft: convertNumberToPixel(args[1]),
			};
		case 2:
			return {
				marginTop: convertNumberToPixel(args[0]),
				marginRight: convertNumberToPixel(args[1]),
				marginBottom: convertNumberToPixel(args[0]),
				marginLeft: convertNumberToPixel(args[1]),
			};
		case 1:
			return {
				marginTop: convertNumberToPixel(args[0]),
				marginRight: convertNumberToPixel(args[0]),
				marginBottom: convertNumberToPixel(args[0]),
				marginLeft: convertNumberToPixel(args[0]),
			};
		default:
			return EMPTY_OBJ;
	}
};

const rotate = (deg) => ({
	transform: `rotate(${deg})`,
});

const rotateY = (deg) => ({
	transform: `rotateY(${deg})`,
});

const scale = (x = 1, y) => ({
	WebkitTransform: `scale(${x}, ${typeof y === "undefined" ? x : y})`,
	MozTransform: `scale(${x}, ${typeof y === "undefined" ? x : y})`,
	transform: `scale(${x}, ${typeof y === "undefined" ? x : y})`,
});

const transformOrigin = (x = "50%", y = "50%") => ({
	transformOrigin: `${convertNumberToPixel(x)} ${convertNumberToPixel(y)}`,
});

const transition = (
	prop,
	animationSpeed = 0.15,
	style = "ease-in-out",
	delay = 0
) => ({
	transition: `${prop} ${convertNumberToSecond(
		animationSpeed
	)} ${style} ${convertNumberToSecond(delay)}`,
});

const transitionAll = (
	animationSpeed = 0.15,
	style = "ease-in-out",
	delay = 0
) => transition("all", animationSpeed, style, delay);

const transitionDelay = (delayTime = 0.2) => ({
	transitionDelay: `${convertNumberToSecond(delayTime)}`,
});

const transitionMulti = (...args) => {
	if (args.length) {
		const transitionsArray = [];
		// for each function arg get transition
		args.forEach((arg) => {
			const transitionObj = transition(arg[0], arg[1], arg[2], arg[3]);
			// push the returned transition onto our array of transitions
			transitionsArray.push(transitionObj.transition);
		});
		// return concated transitions
		return {
			transition: transitionsArray.join(", "),
		};
	}
	return EMPTY_OBJ;
};

const translate = (x = 0, y = 0) => ({
	WebkitTransform: `translate(${convertNumberToPixel(
		x
	)}, ${convertNumberToPixel(y)})`,
	MozTransform: `translate(${convertNumberToPixel(x)}, ${convertNumberToPixel(
		y
	)})`,
	transform: `translate(${convertNumberToPixel(x)}, ${convertNumberToPixel(
		y
	)})`,
});

const translate3d = (x = 0, y = 0, z = 0) => ({
	WebkitTransform: `translate3d(${convertNumberToPixel(
		x
	)}, ${convertNumberToPixel(y)}, ${convertNumberToPixel(z)})`,
	MozTransform: `translate3d(${convertNumberToPixel(x)}, ${convertNumberToPixel(
		y
	)}, ${convertNumberToPixel(z)})`,
	transform: `translate3d(${convertNumberToPixel(x)}, ${convertNumberToPixel(
		y
	)}, ${convertNumberToPixel(z)})`,
});

const userSelect = (type) => ({
	MozUserSelect: type,
	WebkitUserSelect: type,
	MsUserSelect: type,
	userSelect: type,
});

const willChange = (prop) => ({
	willChange: `${prop}`,
});

export default {
	appearance,
	animation,
	backfaceVisibility,
	border,
	borderRadius,
	borderRadiuses,
	boxShadow,
	padding,
	perspective,
	placeholder,
	margin,
	rotate,
	rotateY,
	scale,
	transformOrigin,
	transition,
	transitionAll,
	transitionDelay,
	transitionMulti,
	translate,
	translate3d,
	userSelect,
	willChange,
};
