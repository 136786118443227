import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 85 40">
		<path
			d="M53,36 L81,36 L81,8 L53,8 L53,36 Z M67,3.5 C67.82,3.5 68.5,4.18 68.5,5 C68.5,5.82 67.82,6.5 67,6.5 C66.18,6.5 65.5,5.82 65.5,5 C65.5,4.18 66.18,3.5 67,3.5 L67,3.5 Z M81,4 L72.64,4 C71.8,1.68 69.6,0 67,0 C64.4,0 62.2,1.68 61.36,4 L53,4 C52.72,4 52.46,4.02 52.2,4.08 C51.42,4.24 50.72,4.64 50.18,5.18 C49.82,5.54 49.52,5.98 49.32,6.46 C49.12,6.94 49,7.44 49,8 L49,36 C49,36.54 49.12,37.08 49.32,37.56 C49.52,38.04 49.82,38.46 50.18,38.84 C50.72,39.38 51.42,39.78 52.2,39.94 C52.46,39.98 52.72,40 53,40 L81,40 C83.2,40 85,38.2 85,36 L85,8 C85,5.8 83.2,4 81,4 L81,4 Z M57,16 L77,16 L77,12 L57,12 L57,16 Z M57,24 L77,24 L77,20 L57,20 L57,24 Z M57,32 L71,32 L71,28 L57,28 L57,32 Z"
			fill={styling.colors.primary}
			fillRule="evenodd"
		/>
		<path
			d="M29.1839228,17 C30.2643087,17 31.1571896,17.8200413 31.2361283,18.8513054 L31.2418006,19 L31.2418006,43 C31.2418006,44.05 30.3980282,44.9177686 29.3369205,44.9944872 L29.1839228,45 L16.8366559,45 C15.7562701,45 14.8633892,44.1799587 14.7844504,43.1486946 L14.7787781,43 L14.7787781,19 C14.7787781,17.95 15.6225506,17.0822314 16.6836583,17.0055128 L16.8366559,17 L29.1839228,17 Z M27.126045,21 L18.8945338,21 L18.8945338,41 L27.126045,41 L27.126045,21 Z M23.0102894,23 C24.1462379,23 25.0681672,23.894 25.0681672,25 C25.0681672,26.106 24.1462379,27 23.0102894,27 C21.8743408,27 20.9524116,26.106 20.9524116,25 C20.9524116,23.894 21.8743408,23 23.0102894,23 Z M23.0098778,6.9996 C26.8491919,6.9996 30.3423708,8.45733333 32.9317528,10.8488881 L33.196373,11.0996 L30.2947653,13.9196 C28.4220965,12.1216 25.8497492,10.9996 23.0098778,10.9996 C20.2883344,10.9996 17.8124859,12.0300479 15.9626869,13.6984031 L15.7249904,13.9196 L12.8233826,11.0996 C15.4389453,8.5596 19.0402315,6.9996 23.0098778,6.9996 Z M23.0102894,-1 C29.2662379,-1 34.9254019,1.46 39,5.46 L39,5.46 L36.0983923,8.282 C32.7646302,5.02 28.1344051,3 23.0102894,3 C17.8861736,3 13.2559486,5.02 9.90160772,8.26 L9.90160772,8.26 L7,5.44 C11.0951768,1.46 16.7543408,-1 23.0102894,-1 Z"
			fill={styling.colors.iconSecondary}
			transform="translate(23.000000, 22.000000) scale(-1, 1) rotate(270.000000) translate(-23.000000, -22.000000) "
		/>
	</svg>
);
