import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 70 40">
		<path
			d="M50.0059675,25.7 C46.7839675,25.7 44.1419675,23.134 44.1419675,20 C44.1419675,16.866 46.7839675,14.3 50.0059675,14.3 C53.2279675,14.3 55.8679675,16.866 55.8679675,20 C55.8679675,23.134 53.2279675,25.7 50.0059675,25.7 L50.0059675,25.7 Z M65.2619675,21.932 C65.3659675,21.3 65.3999675,20.634 65.3999675,20 C65.3999675,19.334 65.3299675,18.7 65.2619675,18.034 L69.6159675,14.734 C69.9939675,14.432 70.1299675,13.866 69.8559675,13.434 L65.7419675,6.534 C65.4679675,6.1 64.9539675,5.934 64.4739675,6.1 L59.3659675,8.1 C58.3019675,7.3 57.1719675,6.634 55.9019675,6.134 L55.1479675,0.832 C55.0459675,0.366 54.6339675,-3.55271368e-15 54.1199675,-3.55271368e-15 L45.8919675,-3.55271368e-15 C45.3779675,-3.55271368e-15 44.9659675,0.366 44.8639675,0.866 L44.1079675,6.166 C42.8759675,6.666 41.6739675,7.3 40.6459675,8.1 L35.5379675,6.1 C35.0579675,5.934 34.5439675,6.1 34.2699675,6.534 L30.1559675,13.434 C29.8799675,13.866 30.0179675,14.402 30.3959675,14.734 L34.7499675,18.034 C34.6459675,18.7 34.5779675,19.334 34.5779675,20 C34.5779675,20.666 34.6459675,21.3 34.7139675,21.968 L30.3599675,25.266 C29.9839675,25.6 29.8799675,26.134 30.1559675,26.568 L34.2699675,33.466 C34.5439675,33.9 35.0579675,34.068 35.5379675,33.9 L40.6459675,31.902 C41.7099675,32.7 42.8399675,33.366 44.1079675,33.866 L44.8639675,39.166 C44.9659675,39.634 45.3779675,40 45.8919675,40 L54.1199675,40 C54.6339675,40 55.0459675,39.634 55.1479675,39.134 L55.9019675,33.832 C57.1359675,33.332 58.3019675,32.666 59.3659675,31.866 L64.4739675,33.866 C64.9539675,34.034 65.4679675,33.866 65.7419675,33.434 L69.8559675,26.534 C70.1299675,26.1 69.9939675,25.568 69.6159675,25.234 L65.2619675,21.932 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M26,2 L26,8 L14.002,8 L14.002,2 L0,2 L0,18 L14.002,18 L14.002,12 L18,12 L18,32 L26,32 L26,38 L40,38 L40,22 L26,22 L26,28 L22,28 L22,12 L26,12 L26,18 L40,18 L40,2 L26,2 Z M30.002,14.002 L36.002,14.002 L36.002,6 L30.002,6 L30.002,14.002 Z M4,14.002 L10.002,14.002 L10.002,6 L4,6 L4,14.002 Z M30.002,34.002 L36.002,34.002 L36.002,26 L30.002,26 L30.002,34.002 Z"
			fill={styling.colors.iconSecondary}
			fillRule="evenodd"
		/>
	</svg>
);
