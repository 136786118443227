import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 73 40">
		<path
			d="M64.42,21.36 C65.94,20.5 67,18.88 67,17 C67,14.238 64.76,12 62,12 C59.24,12 57,14.238 57,17 C57,18.88 58.06,20.5 59.58,21.36 C60.3,21.76 61.12,22 62,22 C62.88,22 63.7,21.76 64.42,21.36 M62,25 C58.32,25 51,27.02 51,31 L51,34 L73,34 L73,31 C73,27.02 65.68,25 62,25 M47.1,30 L38.54,30 C40.52,29 43.94,28 47,28 C47.22,28 47.46,28.02 47.68,28.02 C48.36,26.56 49.54,25.36 50.96,24.4 C49.5,24.14 48.12,24 47,24 C42.32,24 33,26.34 33,31 L33,34 L47,34 L47,31 C47,30.66 47.04,30.318 47.1,30 M47,10 C48.66,10 50,11.34 50,13 C50,14.66 48.66,16 47,16 C45.34,16 44,14.66 44,13 C44,11.34 45.34,10 47,10 M47,20 C50.86,20 54,16.86 54,13 C54,9.14 50.86,6 47,6 C43.14,6 40,9.14 40,13 C40,16.86 43.14,20 47,20"
			fill={styling.colors.primary}
		/>
		<path
			d="M21.1111111,8.88888889 L17.7777778,8.88888889 L17.7777778,22.2222222 L28.3333333,28.5555556 L30,25.82 L21.1111111,20.5555556 L21.1111111,8.88888889 Z M20,35.5555556 C11.4,35.5555556 4.44444444,28.5977778 4.44444444,20 C4.44444444,11.4 11.4,4.44444444 20,4.44444444 C28.6,4.44444444 35.5555556,11.4 35.5555556,20 C35.5555556,28.5977778 28.6,35.5555556 20,35.5555556 L20,35.5555556 Z M20,0 C8.95555556,0 0,8.95333333 0,20 C0,31.0444444 8.93333333,40 20,40 C31.0444444,40 40,31.0444444 40,20 C40,8.95333333 31.0444444,0 20,0 L20,0 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
