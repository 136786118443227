import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 68 47">
		<path
			d="M40.539,21.332 C41.477,23.512 41.999,25.912 41.999,28.436 C41.999,38.374 33.941,46.436 23.999,46.436 C19.021,46.436 14.539,44.416 11.281,41.156 L11.281,41.156 L14.121,38.316 C16.639,40.856 20.139,42.436 23.999,42.436 C31.741,42.436 37.999,36.176 37.999,28.436 C37.999,27.05 37.791,25.716 37.419,24.454 L37.419,24.454 Z M23.9998,10.4356 C26.2135143,10.4356 28.3306571,10.8443041 30.2903787,11.5768427 L30.7398,11.7516 L27.5778,14.9136 C26.4338,14.6116 25.2398,14.4356 23.9998,14.4356 C16.3643946,14.4356 10.1692595,20.527554 10.0032177,28.1226388 L9.9998,28.4356 L15.9998,28.4356 L7.9998,36.4156 L-0.0002,28.4356 L5.9998,28.4356 C5.9998,18.4956 14.0598,10.4356 23.9998,10.4356 Z M23.872,29.5 C25.8222778,29.5 29.6516451,30.4489969 29.8628828,32.3359632 L29.872,32.5 L29.872,34 L17.872,34 L17.872,32.5 C17.872,30.504 21.866,29.5 23.872,29.5 Z M23.872,22 C25.526,22 26.872,23.346 26.872,25 C26.872,26.654 25.526,28 23.872,28 C22.218,28 20.872,26.654 20.872,25 C20.872,23.346 22.218,22 23.872,22 Z"
			fill={styling.colors.iconSecondary}
		/>
		<polygon
			fill={styling.colors.primary}
			points="55.872 0 60.452 4.58 50.692 14.34 42.692 6.34 27.872 21.18 30.692 24 42.692 12 50.692 20 63.292 7.42 67.872 12 67.872 0"
		/>
	</svg>
);
