import { arrayOf, bool, exact, string, number, object } from "prop-types";

export const ImagePropTypes = {
	fluid: exact({
		sizes: string,
		src: string,
		srcSet: string,
	}),
	title: string,
};

export const ImageArrayPropTypes = arrayOf(
	exact({
		node: exact({
			name: string,
			childImageSharp: exact({
				original: exact({
					height: number,
					src: string,
					width: number,
				}),
			}),
		}),
	})
);

export const HeaderPropTypes = exact({
	backButton: string,
	resortGuestsMenu: string,
	resortGuestsStrap: string,
	resortGuestsBody: string,
	resortGuestsButton: string,
	instructorsMenu: string,
	instructorsStrap: string,
	instructorsBody: string,
	instructorsButton: string,
	havingTroubleMenu: string,
	havingTroubleStrap: string,
	havingTroubleBody: string,
	havingTroubleButton: string,
	havingTroubleThankYou: string,
	mountainViewStrap: string,
	classManagementStrap: string,
	oneStopShopMenu: string,
});

export const LoginResortsPropTypes = arrayOf(
	exact({
		node: exact({
			slug: string,
			title: string,
		}),
	})
);

export const MenuItemPropType = exact({
	node: exact({
		title: string,
		slug: string,
	}),
});

export const CallToActionPropTypes = {
	title: string.isRequired,
	slug: string.isRequired,
	buttonText: string.isRequired,
	thankYou: string.isRequired,
};

export const TestimonialPropTypes = {
	fullName: string,
	avatar: exact(ImagePropTypes),
	position: string,
	body: string,
	resort: exact({
		title: string,
		logo: exact(ImagePropTypes),
	}),
};

export const ResortGroupsPropTypes = arrayOf(
	exact({
		id: string,
		title: string,
		logo: exact(ImagePropTypes),
		isLogoVisible: bool,
		resorts: arrayOf(
			exact({
				id: string,
				title: string,
				website: string,
				logo: exact(ImagePropTypes),
			})
		),
	})
);

export const KeyFeaturePropTypes = {
	title: string,
	slug: string,
	featureType: string,
	strap: string,
	body: exact({
		json: object,
	}),
};

export const RelatedProductPropTypes = {
	title: string,
	slug: string,
	orderHint: number,
};