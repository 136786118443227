import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 70 40">
		<path
			d="M26.3396,0.0002 L28.4596,2.1002 C28.9996,2.6402 29.3396,3.4002 29.3396,4.2182 L29.3396,4.2182 L29.2796,4.8602 L27.3796,14.0002 L33.4536,14.0002 L29.4536,18.0002 L22.4596,18.0002 L24.6796,7.3202 L15.9996,16.0002 L15.9996,36.0002 L33.9996,36.0002 L38.1816,26.2422 L43.9996,20.4262 L43.9996,22.0002 C43.9996,22.5202 43.8996,23.0002 43.7196,23.4602 L43.7196,23.4602 L37.6796,37.5602 C37.0796,39.0002 35.6596,40.0002 33.9996,40.0002 L33.9996,40.0002 L15.9996,40.0002 C13.7996,40.0002 11.9996,38.2002 11.9996,36.0002 L11.9996,36.0002 L11.9996,16.0002 C11.9996,14.9002 12.4396,13.9002 13.1796,13.1802 L13.1796,13.1802 L26.3396,0.0002 Z M8,16 L8,40 L0,40 L0,16 L8,16 Z"
			fill={styling.colors.iconSecondary}
		/>
		<polygon
			fill={styling.colors.primary}
			points="58 2 62.58 6.58 52.82 16.34 44.82 8.34 30 23.18 32.82 26 44.82 14 52.82 22 65.42 9.42 70 14 70 2"
		/>
	</svg>
);
