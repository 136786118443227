import { Link } from "gatsby";
import { arrayOf, string } from "prop-types";
import React from "react";

import { MenuItemPropType } from "~/types";
import Brand from "Components/Brand";
import { EMPTY_ARR } from "Services/constants/empty";
import Styles from "./styles.css";
import onePercent from '~/images/sustainability/percentforplanet.png';
import climateNeutral from '~/images/sustainability/climateneutralcertified.png';


const Footer = ({
	body,
	copyright,
	lang,
	legal,
	legalMenu,
	products,
	productsMenu,
	signInMenu,
}) => (
	<div css={Styles.wrapper}>
		<footer css={Styles.footer}>
			<div css={Styles.brand}>
				<Link css={Styles.brandLink} to={`/${lang}/`}>
					<Brand />
				</Link>
				<p>
					<strong>{body}</strong>
				</p>
				<p>
					©{new Date().getFullYear()} {copyright}
				</p>
			</div>
			<div css={Styles.menus}>
				<div css={Styles.productsMenu}>
					<div css={Styles.menuHeader}>{productsMenu}</div>
					<nav>
						{products.map((productItem) => (
							<Link
								key={productItem.node.slug}
								activeStyle={Styles.menuLinkActive}
								css={Styles.menuLink}
								to={`/${lang}/product/${productItem.node.slug}/`}>
								{productItem.node.title}
							</Link>
						))}
					</nav>
				</div>
				<div css={Styles.legalMenu}>
					<div css={Styles.menuHeader}>{legalMenu}</div>
					<nav>
						{legal.map((legalItem) => (
							<Link
								key={legalItem.node.slug}
								activeStyle={Styles.menuLinkActive}
								css={Styles.menuLink}
								to={`/${lang}/${legalItem.node.slug}/`}>
								{legalItem.node.title}
							</Link>
						))}
					</nav>
				</div>
			</div>
		</footer>
	</div>
);

Footer.defaultProps = {
	products: EMPTY_ARR,
	legal: EMPTY_ARR,
};

Footer.propTypes = {
	lang: string,
	body: string,
	copyright: string,
	productsMenu: string,
	products: arrayOf(MenuItemPropType),
	legal: arrayOf(MenuItemPropType),
	legalMenu: string,
	signInMenu: string,
};

export default Footer;
