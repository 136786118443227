import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 64 36">
		<polygon
			fill={styling.colors.primary}
			points="42 0 20 12 28 16.36 28 28.36 42 36 56 28.36 56 16.36 60 14.18 60 28 64 28 64 12"
		/>
		<path
			d="M22,0 L0,12 L8,16.36 L8,28.36 L22,36 L36,28.36 L36,16.36 L40,14.18 L40,28 L44,28 L44,12 L22,0 Z M8.36,12 L22,4.562 L35.64,12 L22,19.44 L8.36,12 Z M32,18.538 L32,25.982 L22,31.438 L12,25.982 L12,18.538 L22,24 L32,18.538 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
