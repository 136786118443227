import styling from "Services/styling";

export default {
	wrapper: {
		backgroundColor: styling.colors.backgroundDefault,
	},
	footer: {
		minHeight: styling.sizes.footerMinHeight,
		fontSize: "1.4rem",
		lineHeight: "2rem",
		display: "flex",
		flexDirection: "column-reverse",
		maxWidth: styling.sizes.wrapperMinWidth,
		...styling.mixins.margin(0, "auto"),
		...styling.mixins.padding(40, 50, 50),
		"p:first-of-type": {
			...styling.mixins.margin("auto", 0, 0),
		},
		[styling.sizes.breakpoints.medium]: {
			flexDirection: "row",
			...styling.mixins.padding(40, 70, 50),
		},
	},
	brand: {
		position: "relative",
		width: "100%",
		...styling.mixins.padding(0, 36, 0, 0),
		[styling.sizes.breakpoints.medium]: {
			display: "flex",
			alignItems: "end",
			flexGrow: 1,
			flexDirection: "column",
			width: "50%",
			...styling.mixins.padding(0),
		},
	},
	brandLink: {
		display: "block",
		...styling.mixins.margin(0, 0, 20),
		[styling.sizes.breakpoints.medium]: {
			position: "absolute",
			top: 0,
			left: 0,
			...styling.mixins.margin(0),
		},
	},
	menus: {
		position: "relative",
		width: "100%",
		display: "flex",
		...styling.mixins.padding(0, 0, 130),
		[styling.sizes.breakpoints.medium]: {
			width: "50%",
		},
	},
	productsMenu: {
		width: "50%",
		...styling.mixins.padding(0, 30, 0, 0),
	},
	legalMenu: {
		width: "50%",
	},
	menuHeader: {
		color: styling.colors.linkDefault,
		fontSize: "1.6rem",
		fontWeight: 700,
		...styling.mixins.margin(0, 0, 20),
	},
	menuLink: {
		display: "block",
		color: styling.colors.linkDefault,
		...styling.mixins.transition("color"),
		":hover, :focus, :active": {
			color: styling.colors.linkInteract,
		},
	},
	menuLinkActive: {
		color: styling.colors.linkInteract,
	},
	sustainLogos: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: 290,
		marginTop: 50,
		[styling.sizes.breakpoints.medium]: {
			position: "absolute",
			top: 100,
			left: 0,
			...styling.mixins.margin(0),
		},
	},
};
