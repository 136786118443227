import { EMPTY_ARR } from "Services/constants/empty";
import styling from "Services/styling";

export default {
	footer: {
		textAlign: "center",
		...styling.mixins.padding(20, 40, 40),
	},
	forgotLink: {
		color: styling.colors.textQuadrinary,
		fontSize: "1.6rem",
		lineHeight: "2.4rem",
		cursor: "pointer",
		...styling.mixins.border(EMPTY_ARR, EMPTY_ARR, [
			1,
			"dashed",
			styling.colors.textQuadrinary,
		]),
		...styling.mixins.transitionMulti(["border-color"], ["color"]),
		":hover": {
			color: styling.colors.linkInteract,
			borderColor: styling.colors.linkInteract,
		},
	},
};
