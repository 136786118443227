import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 40 28">
		<path
			d="M29.0002,19.0002 C32.6802,19.0002 40.0002,21.0202 40.0002,25.0002 L40.0002,25.0002 L40.0002,28.0002 L18.0002,28.0002 L18.0002,25.0002 C18.0002,21.0202 25.3202,19.0002 29.0002,19.0002 Z M29.0002,6.0002 C31.7602,6.0002 34.0002,8.2382 34.0002,11.0002 C34.0002,12.8802 32.9402,14.5002 31.4202,15.3602 C30.7002,15.7602 29.8802,16.0002 29.0002,16.0002 C28.1202,16.0002 27.3002,15.7602 26.5802,15.3602 C25.0602,14.5002 24.0002,12.8802 24.0002,11.0002 C24.0002,8.2382 26.2402,6.0002 29.0002,6.0002 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M14.1002,24.0002 L5.5402,24.0002 C7.5202,23.0002 10.9402,22.0002 14.0002,22.0002 C14.2202,22.0002 14.4602,22.0202 14.6802,22.0202 C15.3602,20.5602 16.5402,19.3602 17.9602,18.4002 C16.5002,18.1402 15.1202,18.0002 14.0002,18.0002 C9.3202,18.0002 0.0002,20.3402 0.0002,25.0002 L0.0002,28.0002 L14.0002,28.0002 L14.0002,25.0002 C14.0002,24.6602 14.0402,24.3182 14.1002,24.0002 M14.0002,4.0002 C15.6602,4.0002 17.0002,5.3402 17.0002,7.0002 C17.0002,8.6602 15.6602,10.0002 14.0002,10.0002 C12.3402,10.0002 11.0002,8.6602 11.0002,7.0002 C11.0002,5.3402 12.3402,4.0002 14.0002,4.0002 M14.0002,14.0002 C17.8602,14.0002 21.0002,10.8602 21.0002,7.0002 C21.0002,3.1402 17.8602,0.0002 14.0002,0.0002 C10.1402,0.0002 7.0002,3.1402 7.0002,7.0002 C7.0002,10.8602 10.1402,14.0002 14.0002,14.0002"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
