import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 74 40">
		<path
			d="M65.42,21.36 C66.94,20.5 68,18.882 68,17 C68,14.24 65.76,12 63,12 C60.24,12 58,14.24 58,17 C58,18.882 59.06,20.5 60.58,21.36 C61.3,21.76 62.12,22 63,22 C63.88,22 64.7,21.76 65.42,21.36 M63,25 C59.32,25 52,27.02 52,31 L52,34 L74,34 L74,31 C74,27.02 66.68,25 63,25 M48.1,30 L39.54,30 C41.52,29 44.94,28 48,28 C48.22,28 48.46,28.02 48.68,28.02 C49.36,26.562 50.54,25.36 51.96,24.402 C50.5,24.142 49.12,24 48,24 C43.32,24 34,26.34 34,31 L34,34 L48,34 L48,31 C48,30.66 48.04,30.32 48.1,30 M48,10 C49.66,10 51,11.34 51,13 C51,14.66 49.66,16 48,16 C46.34,16 45,14.66 45,13 C45,11.34 46.34,10 48,10 M48,20 C51.86,20 55,16.86 55,13 C55,9.142 51.86,6 48,6 C44.14,6 41,9.142 41,13 C41,16.86 44.14,20 48,20"
			fill={styling.colors.primary}
		/>
		<path
			d="M19.98,0 C31.04,0 40,8.96 40,20 C40,31.04 31.04,40 19.98,40 C8.94,40 0,31.04 0,20 C0,8.96 8.94,0 19.98,0 Z M20,4 C11.16,4 4,11.16 4,20 C4,28.84 11.16,36 20,36 C28.84,36 36,28.84 36,20 C36,11.16 28.84,4 20,4 Z M30,24 C28.44,28.68 24.56,32 20,32 C15.44,32 11.56,28.68 10,24 L10,24 Z M27,12 C28.656,12 30,13.344 30,15 C30,16.656 28.656,18 27,18 C25.342,18 24,16.656 24,15 C24,13.344 25.342,12 27,12 Z M13,12 C14.656,12 16,13.344 16,15 C16,16.656 14.656,18 13,18 C11.342,18 10,16.656 10,15 C10,13.344 11.342,12 13,12 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
