import { EMPTY_ARR } from "Services/constants/empty";
import styling from "Services/styling";

export default {
	wrapper: (isAtTop) => ({
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		zIndex: styling.zIndex.header,
		backgroundColor: isAtTop
			? styling.colors.transparent
			: styling.colors.backgroundDefault,
		...styling.mixins.transition("background-color"),
	}),
	header: {
		minHeight: styling.sizes.desktopMenuHeight,
		fontSize: "1.4rem",
		lineHeight: "2rem",
		display: "flex",
		maxWidth: styling.sizes.wrapperMinWidth,
		...styling.mixins.margin(0, "auto"),
		...styling.mixins.padding(30, 40, 0),
		[styling.sizes.breakpoints.medium]: {
			...styling.mixins.padding(30, 70, 0),
		},
	},
	brand: {
		position: "relative",
		width: "50%",
		h1: {
			width: 0,
			display: "inline-block",
			textIndex: "-100%",
			overflow: "hidden",
		},
	},
	brandLink: {
		position: "absolute",
		top: 0,
		left: 0,
	},
	menus: {
		position: "relative",
		width: "50%",
		display: "flex",
		fontSize: "1.6rem",
		lineHeight: "1.9rem",
		justifyContent: "end",
	},
	menu: {
		position: "relative",
		display: "none",
		[styling.sizes.breakpoints.medium]: {
			display: "flex",
			...styling.mixins.margin(0, 0, 0, "auto"),
		},
	},
	mobileMenu: {
		display: "flex",
		...styling.mixins.margin(0, 0, 0, "auto"),
		[styling.sizes.breakpoints.medium]: {
			display: "none",
		},
	},
	hamburger: {
		height: 2,
		width: 24,
		position: "relative",
		backgroundColor: styling.colors.textTertiary,
		cursor: "pointer",
		...styling.mixins.margin(12, 0, 0, 20),
		":before, :after": {
			content: '""',
			position: "absolute",
			left: 0,
			right: 0,
			height: 0,
		},
		":before": {
			top: -8,
			...styling.mixins.border(
				[2, "solid", styling.colors.textTertiary],
				EMPTY_ARR,
				EMPTY_ARR
			),
			...styling.mixins.padding(0, 0, 8),
		},
		":after": {
			bottom: -8,
			...styling.mixins.border(EMPTY_ARR, EMPTY_ARR, [
				2,
				"solid",
				styling.colors.textTertiary,
			]),
			...styling.mixins.padding(8, 0, 0),
		},
	},
	menuTitle: (isActive) => ({
		position: "relative",
		textAlign: "right",
		color: styling.colors.linkDefault,
		...styling.mixins.padding(0, 0, 17),
		...styling.mixins.margin(0, 10),
		...styling.mixins.border(EMPTY_ARR, EMPTY_ARR, [
			1,
			"solid",
			isActive ? styling.colors.linkDefault : styling.colors.transparent,
		]),
		cursor: "pointer",
		":hover, :focus, :active": {
			...styling.mixins.border(EMPTY_ARR, EMPTY_ARR, [
				1,
				"solid",
				styling.colors.linkDefault,
			]),
		},
		":first-of-type:after": {
			content: '""',
			top: 0,
			right: "100%",
			width: 60,
			height: 52,
			position: "absolute",
		},
	}),
	languageTitle: (isActive) => ({
		position: "relative",
		textAlign: "right",
		fontSize: "1.2rem",
		lineHeight: "2.6rem",
		color: styling.colors.linkDefault,
		...styling.mixins.padding(0, 0, 17),
		...styling.mixins.margin(0, 0, 0, 10),
		cursor: "pointer",
		[styling.sizes.breakpoints.medium]: {
			lineHeight: "inherit",
		},
	}),
	mobileFlyout: (isOpen) => ({
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: styling.zIndex.header + 1,
		backgroundColor: styling.colors.backgroundDefault,
		overflow: "hidden",
		...styling.mixins.transition("transform"),
		...styling.mixins.translate(`${isOpen ? 0 : "100%"}`, "0"),
	}),
};
