import { keyframes } from "@emotion/core";
import { string, number, object } from "prop-types";
import * as React from "react";

import { EMPTY_OBJ } from "Services/constants/empty";
import styling from "Services/styling";

const spinnerStyles = {
	...styling.utils.maxRounded,
	position: "absolute",
	top: "50%",
	left: "50%",
	overflow: "hidden",
};

const spinKeyframes = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

const Spinner = (props) => {
	const halfNegativeSize = props.size / -2;
	return (
		<div
			css={[
				spinnerStyles,
				props.style,
				{
					...styling.mixins.animation(
						spinKeyframes,
						props.speed,
						"linear",
						"infinite"
					),
					height: props.size,
					width: props.size,
					...styling.mixins.margin(halfNegativeSize, 0, 0, halfNegativeSize),
					...styling.mixins.border(
						[props.thickness, "solid", props.color],
						[props.thickness, "solid", styling.colors.transparent],
						[props.thickness, "solid", styling.colors.transparent]
					),
				},
			]}
		/>
	);
};

Spinner.propTypes = {
	color: string,
	size: number,
	speed: number,
	style: object,
	thickness: number,
};

Spinner.defaultProps = {
	color: styling.colors.primary,
	size: 20,
	speed: 0.6,
	style: EMPTY_OBJ,
	thickness: 2,
};

export default Spinner;
