import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 65 55">
		<path
			d="M17,20 C17,21.0612698 17.206651,22.0742738 17.5819088,23.0009677 L12,23 L12,35 C12,38.3 14.7,41 18,41 C21.225,41 23.8769628,38.4213326 23.9958408,35.2240456 L24,35 L24.0000487,27.9381123 C24.3276273,27.9789588 24.6613564,28 25,28 C28.3490144,28 31.2173854,25.9421211 32.4095828,23.0218933 C34.3539799,23.2209487 35.8973112,24.8291033 35.9950803,26.8011409 L36,27 L36,29 C36,34.1 32.16,38.26 27.22,38.88 C26.0006452,41.7832258 23.4326951,43.9934235 20.3137062,44.7311631 L20,44.8 L20,51 L28,51 L28,55 L8,55 L8,51 L16,51 L16,44.8 C12.74,44.14 10.04,41.88 8.78,38.88 C3.92981818,38.2712727 0.14,34.2501025 0.00379179564,29.277234 L0,29 L0,27 C0,24.8666667 1.69256198,23.1094582 3.80114089,23.0049197 L4,23 L8,23 L8,19 L17.0618955,18.999986 C17.0210439,19.3275847 17,19.6613348 17,20 Z M32,29 C32,31.6 30.32,33.8 28,34.64 L28,27 L32,27 L32,29 Z M8,34.64 C5.68,33.8 4,31.6 4,29 L4,27 L8,27 L8,34.64 Z"
			fill={styling.colors.iconSecondary}
		/>
		<path
			d="M65,4 C65,6.2 63.2,8 61,8 C60.64,8 60.3,7.958 59.98,7.86 L52.86,14.958 C52.96,15.28 53,15.638 53,16 C53,18.2 51.2,20 49,20 C46.8,20 45,18.2 45,16 C45,15.638 45.04,15.28 45.14,14.958 L40.04,9.86 C39.72,9.958 39.36,10 39,10 C38.64,10 38.28,9.958 37.96,9.86 L28.86,18.978 C28.96,19.298 29,19.638 29,20 C29,22.2 27.2,24 25,24 C22.8,24 21,22.2 21,20 C21,17.798 22.8,16 25,16 C25.36,16 25.7,16.04 26.02,16.138 L35.14,7.04 C35.04,6.718 35,6.36 35,6 C35,3.798 36.8,2 39,2 C41.2,2 43,3.798 43,6 C43,6.36 42.96,6.718 42.86,7.04 L47.96,12.138 C48.28,12.04 48.64,12 49,12 C49.36,12 49.72,12.04 50.04,12.138 L57.14,5.02 C57.04,4.7 57,4.36 57,4 C57,1.798 58.8,0 61,0 C63.2,0 65,1.798 65,4"
			fill={styling.colors.primary}
		/>
	</svg>
);
