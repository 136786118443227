import { string, object } from "prop-types";
import * as React from "react";

import Spinner from "Components/Spinner";
import { EMPTY_OBJ } from "Services/constants/empty";
import styling from "Services/styling";

const loadingContainerStyle = ({ backgroundColor, style }) => ({
	position: "relative",
	minHeight: 100,
	width: "100%",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	backgroundColor,
	...style,
});

const LoadingScreen = (props) => (
	<div css={loadingContainerStyle(props)}>
		<Spinner />
	</div>
);

LoadingScreen.propTypes = {
	backgroundColor: string,
	style: object,
};

LoadingScreen.defaultProps = {
	backgroundColor: styling.colors.backgroundDefault,
	style: EMPTY_OBJ,
};

export default LoadingScreen;
