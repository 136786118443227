import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 53 50">
		<path
			d="M52.42,19.26 L47.74,14.58 C47.34,14.18 46.84,14 46.32,14 C45.8,14 45.3,14.2 44.92,14.58 L41.26,18.24 L48.76,25.74 L52.42,22.08 C53.2,21.3 53.2,20.04 52.42,19.26 L52.42,19.26 Z M22.84,46 L21,46 L21,44.16 L39.12,26.04 L40.96,27.88 L22.84,46 Z M17,42.5 L17,50 L24.5,50 L46.62,27.88 L39.12,20.38 L17,42.5 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M4,36 L32,36 L32,8 L4,8 L4,36 Z M18,3.5 C18.82,3.5 19.5,4.18 19.5,5 C19.5,5.82 18.82,6.5 18,6.5 C17.18,6.5 16.5,5.82 16.5,5 C16.5,4.18 17.18,3.5 18,3.5 L18,3.5 Z M32,4 L23.64,4 C22.8,1.68 20.6,0 18,0 C15.4,0 13.2,1.68 12.36,4 L4,4 C3.72,4 3.46,4.02 3.2,4.08 C2.42,4.24 1.72,4.64 1.18,5.18 C0.82,5.54 0.52,5.98 0.32,6.462 C0.12,6.94 0,7.44 0,8 L0,36 C0,36.54 0.12,37.08 0.32,37.56 C0.52,38.04 0.82,38.462 1.18,38.84 C1.72,39.382 2.42,39.78 3.2,39.94 C3.46,39.98 3.72,40 4,40 L32,40 C34.2,40 36,38.2 36,36 L36,8 C36,5.802 34.2,4 32,4 L32,4 Z M30,16 L27.18,13.16 L14,26.34 L8.82,21.18 L6,24 L14,32 L30,16 Z"
			fill={styling.colors.iconSecondary}
			fillRule="evenodd"
		/>
	</svg>
);
