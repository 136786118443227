import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 59 32">
		<path
			d="M14.0002,28.0004 L4.0002,28.0004 C4.4002,26.7404 9.1002,24.6604 13.8602,24.1204 L13.9202,24.1204 L14.8402,23.2184 L18.0002,20.1204 C17.2202,20.0404 16.6402,20.0004 16.0002,20.0004 C10.6602,20.0004 0.0002,22.6804 0.0002,28.0004 L0.0002,32.0004 L18.0002,32.0004 L14.0002,28.0004 Z M16.0002,4.0004 C18.2002,4.0004 20.0002,5.7984 20.0002,8.0004 C20.0002,10.2004 18.2002,12.0004 16.0002,12.0004 C13.8002,12.0004 12.0002,10.2004 12.0002,8.0004 C12.0002,5.7984 13.8002,4.0004 16.0002,4.0004 L16.0002,4.0004 Z M16.0002,16.0004 C20.4202,16.0004 24.0002,12.4204 24.0002,8.0004 C24.0002,3.5804 20.4202,0.0004 16.0002,0.0004 C11.5802,0.0004 8.0002,3.5804 8.0002,8.0004 C8.0002,12.4204 11.5802,16.0004 16.0002,16.0004 L16.0002,16.0004 Z"
			fill={styling.colors.iconSecondary}
		/>
		<polygon
			fill={styling.colors.primary}
			points="46.0882 4.1742 50.6682 8.7542 40.9082 18.5142 32.9082 10.5142 18.0882 25.3542 20.9082 28.1742 32.9082 16.1742 40.9082 24.1742 53.5082 11.5942 58.0882 16.1742 58.0882 4.1742"
		/>
	</svg>
);
