const colors = {
	primary: "#0050F0",
	primaryFaded: "#E6F2FD",
	backgroundDefault: "#FFFFFF",
	backgroundCircle: "#F3F5F7",
	boxShadow: "rgba(0,0,0,0.1)",
	iconSecondary: "#1B232C",
	linkDefault: "#1B232C",
	linkInteract: "#0050F0",
	transparent: "rgba(0,0,0,0)",
	textDefault: "#212A35",
	textSecondary: "#2D3945",
	textTertiary: "#14181D",
	textQuadrinary: "#707D8C",
	textQuintary: "#525D69",
	textHeaderDefault: "#202830",
	textOnPrimary: "#FFFFFF",
	ripple: "#DEE4EA",
	modalBackdropBackground: "#1B232C",
	modalBackground: "#FFFFFF",
	inputBorder: "#AEBCCC",
	inputBorderError: "#0050F0",
	inputDisabledText: "#CED6E0",
	inputText: "#596674",
	inputTextError: "#FFFFFF",
	inputBackground: "#FFFFFF",
	orbitBorder: "#D8D8D8",
};

export default colors;
