import { func, object, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { Times } from "Components/Icons";
import { EMPTY_OBJ } from "Services/constants/empty";
import modals from "Services/constants/modals";
import Styles from "./styles.css";

class Modal extends React.Component {
	componentDidUpdate(prevProps) {
		const { modalKey } = this.props;
		if (typeof window === "undefined") {
			return;
		}
		if (modalKey === null && prevProps.modalKey !== null) {
			window.document.body.classList.remove("modal-open");
		} else if (modalKey !== null && prevProps.modalKey === null) {
			window.document.body.classList.add("modal-open");
		}
	}
	render() {
		const { modalKey, modalProps, modalClose } = this.props;
		if (typeof modals[modalKey] === "undefined") {
			return null;
		}
		const Contents = modals[modalKey];
		return (
			<>
				<div css={Styles.modalBackdrop} />
				<div
					className="modal-wrapper"
					css={Styles.modalWrapper}
					onClick={modalClose}>
					<div
						className="modal-inner"
						css={Styles.modalInner}
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}>
						<div css={Styles.closeButton} onClick={modalClose}>
							<Times />
						</div>
						<div css={Styles.modalContent}>
							<Contents {...modalProps} modalClose={modalClose} />
						</div>
					</div>
				</div>
			</>
		);
	}
}

Modal.propTypes = {
	modalKey: string,
	modalProps: object,
	modalClose: func,
};

const mapStateToProps = ({ modalKey, modalProps }) => ({
	modalKey,
	modalProps,
});

const mapDispatchToProps = (dispatch) => ({
	modalClose: () =>
		dispatch({
			type: "MODAL_KEY_SET",
			payload: EMPTY_OBJ,
		}),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Modal);
