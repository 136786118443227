const breakpointSmall = 600;
const breakpointMedium = 900;
const breakpointLarge = 1200;
const breakpointXLarge = 1800;

export default {
	wMinWidth: 375,
	wMinHeight: "100%",
	wrapperMinWidth: 1440,
	desktopMenuHeight: 82,
	footerMinHeight: 300,
	breakpoints: {
		small: `@media (min-width: ${breakpointSmall}px)`,
		medium: `@media (min-width: ${breakpointMedium}px)`,
		large: `@media (min-width: ${breakpointLarge}px)`,
		xlarge: `@media (min-width: ${breakpointXLarge}px)`,
		breakpointSmall,
		breakpointMedium,
		breakpointLarge,
		breakpointXLarge,
	},
};
