import {
	arrayOf,
	exact,
	number,
	oneOfType,
	bool,
	func,
	string,
} from "prop-types";
import * as React from "react";

import { isPresent } from "Services/helper-utils";
import { EMPTY_ARR } from "Services/constants/empty";
import Styles from "./styles.css";

class InputSelect extends React.Component {
	static propTypes = {
		isDisabled: bool,
		isRequired: bool,
		isValid: bool,
		items: arrayOf(
			exact({
				title: string,
				value: oneOfType([string, number]),
			})
		),
		label: string,
		name: string,
		onChange: func,
		value: string,
	};
	static defaultProps = {
		items: EMPTY_ARR,
	};
	constructor(props) {
		super(props);
		this.state = {
			errors: this.validate(props.value, props.isRequired),
			isTouched: false,
		};
	}
	validate = (value, isRequired) => {
		if (isRequired && !isPresent(value)) {
			return ["Required"];
		}
		return EMPTY_ARR;
	};
	handleOnChange = (e) => {
		const { isDisabled, isRequired, name, onChange } = this.props;
		if (isDisabled) return;
		const errors = this.validate(e.target.value, isRequired);
		this.setState({
			errors,
		});
		onChange(name, e.target.value, errors.length === 0);
	};
	handleOnBlur = () => {
		if (!this.state.isTouched) {
			this.setState({
				isTouched: true,
			});
		}
	};
	render() {
		const { isDisabled, isRequired, items, label, name, value } = this.props;
		const { errors, isTouched } = this.state;
		return (
			<div css={Styles.wrapper(isDisabled)}>
				{isDisabled && <div css={Styles.inputCover} />}
				<select
					css={Styles.input(errors.length > 0, isTouched)}
					disabled={isDisabled}
					name={name}
					onBlur={this.handleOnBlur}
					onChange={this.handleOnChange}
					required={isRequired}
					tabIndex={1}
					value={value}>
					<option value="" disabled hidden>
						{isRequired ? `*${label}` : label}
					</option>
					{items.map((item) => (
						<option key={item.value} value={item.value}>
							{item.title}
						</option>
					))}
				</select>
				{errors.length > 0 && (
					<div css={Styles.error(isTouched)}>{errors[0]}</div>
				)}
			</div>
		);
	}
}

export default InputSelect;
