import styling from "Services/styling";

export default {
	wrapper: (isDisabled) => ({
		position: "relative",
		opacity: isDisabled ? 0.5 : 1,
		cursor: isDisabled ? "loading" : void 0,
		...styling.mixins.margin(0, 0, 20),
		...styling.mixins.transition("opacity"),
	}),
	inputCover: {
		position: "absolute",
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		zIndex: 2,
	},
	input: (hasErrors, isTouched) => ({
		color: styling.colors.inputText,
		caretColor: styling.colors.primary,
		fontSize: "1.4rem",
		lineHeight: "1.8rem",
		minHeight: 96,
		width: "100%",
		outline: "none",
		resize: "vertical",
		...styling.mixins.padding(21, 10),
		backgroundColor: styling.colors.inputBackground,
		...styling.mixins.borderRadius(4),
		...styling.mixins.border([
			1,
			"solid",
			hasErrors && isTouched
				? styling.colors.inputBorderError
				: styling.colors.inputBorder,
		]),
		...styling.mixins.transition("border-color"),
		":focus": {
			...styling.mixins.border([
				1,
				"solid",
				hasErrors
					? styling.colors.inputBorderError
					: styling.colors.inputBorder,
			]),
		},
		":focus + div": {
			opacity: hasErrors ? 1 : 0,
		},
		...styling.mixins.placeholder({
			color: styling.colors.inputBorder,
		}),
	}),
	error: (isTouched) => ({
		position: "absolute",
		right: 0,
		bottom: 0,
		fontSize: "1rem",
		lineHeight: "1.6rem",
		color: styling.colors.inputTextError,
		backgroundColor: styling.colors.inputBorderError,
		opacity: isTouched ? 1 : 0,
		...styling.mixins.padding(0, 4),
		...styling.mixins.transition("opacity"),
	}),
};
