import React from "react";

import styling from "Services/styling";

export default ({ fill = styling.colors.inputBorder }) => (
	<svg viewBox="0 0 15 15">
		<defs>
			<polygon
				id="path-1"
				points="6.54247233 -1 6.54247233 6.54247233 -1 6.54247233 -1 8.42809042 6.54247233 8.42809042 6.54247233 15.9705627 8.42997603 15.9705627 8.42997603 8.42809042 15.9705627 8.42809042 15.9705627 6.54247233 8.42997603 6.54247233 8.42997603 -1"></polygon>
		</defs>
		<use
			fill={fill}
			transform="translate(7.485281, 7.485281) rotate(45.000000) translate(-7.485281, -7.485281) "
			xlinkHref="#path-1"
		/>
	</svg>
);
