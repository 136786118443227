import isEmpty from "lodash.isempty";

export const isString = (obj) => toString.call(obj) === "[object String]";

export const isUndefined = (obj) => typeof obj === "undefined";

export const isNull = (obj) => obj === null;

export const isNumber = (obj) => toString.call(obj) === "[object Number]";

export const isBoolean = (value) => value === true || value === false;

export const isRationalNumber = (obj) => Number.isFinite(obj);

export const isDate = (obj) => toString.call(obj) === "[object Date]";

export const isFunction = (obj) => toString.call(obj) === "[object Function]";

export const isValidDate = (obj) => {
	if (!isDate(obj)) {
		return false;
	}
	return !Number.isNaN(obj.getTime());
};

export const isBlank = (obj) => {
	const newObj = obj;
	// null and undefined objs we simply return true
	if (isNull(newObj) || isUndefined(newObj)) {
		return true;
	} else if (isString(newObj)) {
		// string newObjs, we want to trim the string, so we handle it differently
		return isEmpty(newObj.trim());
	} else if (isDate(newObj)) {
		return !isValidDate(newObj);
	} else if (isNumber(newObj)) {
		return !isRationalNumber(newObj);
	} else if (isBoolean(newObj)) {
		return false;
	}

	// all others, we simply default to the isEmpty method
	return isEmpty(newObj);
};

export const isPresent = (obj) => !isBlank(obj);
