import React from "react";
import { bool, number, func, node } from "prop-types";

import Spinner from "Components/Spinner";
import { NOOP } from "Services/constants/empty";
import Styles from "./styles.css";

const Button = (props) => {
	const {
		children,
		isDisabled,
		isLoading,
		onClick,
		onKeyPress,
		tabIndex,
	} = props;
	return (
		<button
			css={Styles.button(isDisabled)}
			onClick={(e) => !isDisabled && onClick(e)}
			onKeyPress={(e) => !isDisabled && onKeyPress(e)}
			tabIndex={tabIndex}>
			<div css={Styles.loadingOverlay(isLoading)}>
				{isLoading && (
					<Spinner color={Styles.spinnerColor} size={30} thickness={3} />
				)}
			</div>
			<span css={Styles.children(isLoading)}>{children}</span>
		</button>
	);
};

Button.propTypes = {
	children: node.isRequired,
	isDisabled: bool,
	isLoading: bool,
	onClick: func,
	onKeyPress: func,
	tabIndex: number,
};

Button.defaultProps = {
	children: null,
	isDisabled: false,
	isLoading: false,
	onClick: NOOP,
	onKeyPress: NOOP,
	tabIndex: 1,
};

export default Button;
