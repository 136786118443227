import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 56 42">
		<path
			d="M38,6 C28.06,6 20,14.058 20,24 C20,33.94 28.04,42 38,42 C47.94,42 56,33.94 56,24 C56,14.058 47.94,6 38,6"
			fill={styling.colors.primary}
		/>
		<path
			d="M18,38 C10.26,38 4,31.74 4,24 C4,16.26 10.26,10 18,10 C25.74,10 32,16.26 32,24 C32,31.74 25.74,38 18,38 L18,38 Z M32.06,12.76 L34.9,9.92 C34.04,8.9 33.1,7.94 32.08,7.1 L29.24,9.94 C26.14,7.46 22.24,5.98 18,5.98 C8.06,5.98 0,14.04 0,23.98 C0,33.92 8.04,41.98 18,41.98 C27.96,41.98 36,33.92 36,23.98 C36,19.76 34.52,15.84 32.06,12.76 L32.06,12.76 Z M16,26 L20,26 L20,14 L16,14 L16,26 Z M12,4 L24,4 L24,0 L12,0 L12,4 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
