import { StaticQuery, graphql } from "gatsby";
import * as React from "react";
import { func, string } from "prop-types";

import styling from "Services/styling";

const wrapperStyle = {
	...styling.mixins.margin(0, 0, 20),
	"> div": {
		...styling.mixins.margin(0, "auto"),
	},
};

// Wait for the recaptcha object to become available
// The promise will resolve as soon as needed, keep resolving with the
// cached object.
const waitForRecaptcha = new Promise((resolve) => {
	const interval = setInterval(() => {
		if (
			typeof window !== "undefined" &&
			typeof window.grecaptcha !== "undefined" &&
			typeof window.grecaptcha.render === "function"
		) {
			clearInterval(interval);
			resolve(window.grecaptcha);
		}
	}, 1000);
});

// script will check if a script with the url already exists before attempting to add it
// this prevents the script being loaded multiple times
const loadScript = (url) => {
	if (
		!Array.from(document.querySelectorAll("script")).some(
			(elm) => elm.src === url
		)
	) {
		const script = document.createElement("script");
		script.setAttribute("src", url);
		script.setAttribute("async", "async");
		script.setAttribute("defer", "defer");
		document.getElementsByTagName("head")[0].appendChild(script);
	}
};

class Recaptcha extends React.Component {
	constructor(props) {
		super(props);
		loadScript("https://www.google.com/recaptcha/api.js");
		this.containerRef = null;
		this.state = {
			widget: null,
		};
	}

	componentDidMount() {
		this.renderGrecaptcha();
	}

	renderGrecaptcha() {
		const {
			setRef,
			sitekey,
			theme,
			type,
			size,
			tabindex,
			hl,
			badge,
			verifyCallback,
			expiredCallback,
			onloadCallback,
		} = this.props;

		waitForRecaptcha.then((grecaptcha) => {
			setRef(grecaptcha);
			const widget = grecaptcha.render(this.containerRef, {
				sitekey,
				theme,
				type,
				size,
				tabindex,
				hl,
				badge,
				callback: verifyCallback,
				"expired-callback": expiredCallback,
			});
			this.setState({ widget });
		});

		if (onloadCallback) {
			onloadCallback();
		}
	}

	render() {
		return (
			<div
				ref={(el) => {
					this.containerRef = el;
				}}
				css={wrapperStyle}
			/>
		);
	}
}

Recaptcha.propTypes = {
	onloadCallback: func,
	verifyCallback: func,
	expiredCallback: func,
	setRef: func.isRequired,
	sitekey: string.isRequired,
	theme: string,
	type: string,
	size: string,
	tabindex: string,
	hl: string,
	badge: string,
};

Recaptcha.defaultProps = {
	onloadCallback: void 0,
	verifyCallback: void 0,
	expiredCallback: void 0,
	theme: "light",
	type: "image",
	size: "normal",
	tabindex: "0",
	hl: "en",
	badge: "bottomright",
};

export default (props) => (
	<StaticQuery
		query={graphql`
			query RecaptchaQuery {
				site {
					siteMetadata {
						recaptchaKey
					}
				}
			}
		`}
		render={(data) => (
			<Recaptcha sitekey={data.site.siteMetadata.recaptchaKey} {...props} />
		)}
	/>
);
