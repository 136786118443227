import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 61 42">
		<path
			d="M18.0006,19.2816 C20.2006,19.2816 22.0006,21.0796 22.0006,23.2816 C22.0006,23.6396 21.9606,23.9996 21.8606,24.3196 L21.8606,24.3196 L26.9606,29.4196 C27.2806,29.3196 27.6406,29.2816 28.0006,29.2816 C28.3606,29.2816 28.7206,29.3196 29.0406,29.4196 L29.0406,29.4196 L32.5326,25.9176 C33.3346,27.0036 34.3506,27.9056 35.5186,28.5936 L35.5186,28.5936 L31.8606,32.2396 C31.9606,32.5596 32.0006,32.9196 32.0006,33.2816 C32.0006,35.4796 30.2006,37.2816 28.0006,37.2816 C25.8006,37.2816 24.0006,35.4796 24.0006,33.2816 C24.0006,32.9196 24.0406,32.5596 24.1406,32.2396 L24.1406,32.2396 L19.0406,27.1396 C18.7206,27.2396 18.3606,27.2816 18.0006,27.2816 C17.6406,27.2816 17.2806,27.2396 16.9606,27.1396 L16.9606,27.1396 L7.8606,36.2616 C7.9606,36.5796 8.0006,36.9196 8.0006,37.2816 C8.0006,39.4796 6.2006,41.2816 4.0006,41.2816 C1.8006,41.2816 0.000599999999,39.4796 0.000599999999,37.2816 C0.000599999999,35.0796 1.8006,33.2816 4.0006,33.2816 C4.3606,33.2816 4.7006,33.3196 5.0186,33.4196 L5.0186,33.4196 L14.1406,24.3196 C14.0406,23.9996 14.0006,23.6396 14.0006,23.2816 C14.0006,21.0796 15.8006,19.2816 18.0006,19.2816 Z M60.5562,8.5975671e-13 L60.5562,11.312 L56.3142,7.07 L52.8922,10.492 C52.1502,9.368 51.1882,8.406 50.0642,7.664 L50.0642,7.664 L53.4842,4.242 L49.2422,8.5975671e-13 L60.5562,8.5975671e-13 Z"
			fill={styling.colors.iconSecondary}
		/>
		<path
			d="M41.1416,22.2422 L36.8996,18.0002 L38.3136,16.5862 L41.1416,19.4142 L46.7976,13.7582 L48.2116,15.1722 L41.1416,22.2422 Z M42.5556,8.0002 C37.0316,8.0002 32.5556,12.4762 32.5556,18.0002 C32.5556,23.5242 37.0316,28.0002 42.5556,28.0002 C48.0796,28.0002 52.5556,23.5242 52.5556,18.0002 C52.5556,12.4762 48.0796,8.0002 42.5556,8.0002 L42.5556,8.0002 Z"
			fill={styling.colors.primary}
		/>
	</svg>
);
