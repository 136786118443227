import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 56 40">
		<path
			d="M39.37,27.45 L32.412,20.492 L35.212,17.692 L39.37,21.85 L47.842,13.378 L50.642,16.178 L39.37,27.45 Z M52,0 L28,0 C25.8,0 24,1.802 24,4 L24,36 C24,38.2 25.782,40 27.982,40 L28,40 L52,40 L52.02,40 C54.22,40 56,38.2 56,36 L56,4 C56,1.802 54.202,0 52,0 L52,0 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M28,36 L4,36 L4,4 L18,4 L18,14 L28,14 L28,36 Z M20,0 L4,0 C1.8,0 0,1.8 0,4 L0,36 C0,38.2 1.78,40 3.98,40 L28,40 C30.2,40 32,38.2 32,36 L32,12 L20,0 Z M8,24 L24,24 L24,20 L8,20 L8,24 Z M8,32 L24,32 L24,28 L8,28 L8,32 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
