import { string } from "prop-types";
import React from "react";

import { LoginResortsPropTypes } from "~/types";
import Form from "Components/Form";
import InputSelect from "Components/InputSelect";
import { EMPTY_ARR } from "Services/constants/empty";
import SharedStyles from "../../styles.css";

class InstructorsLogin extends React.Component {
	isMounted;
	static propTypes = {
		body: string,
		button: string,
		loginResorts: LoginResortsPropTypes,
		menu: string,
	};
	constructor(props) {
		super(props);
		this.state = {
			resortValue: "",
			resortIsValid: false,
			loginItems: props.loginResorts.reduce((items, node) => {
				if (node.node) {
					return items.concat({
						title: node.node.title,
						value: node.node.slug,
					});
				}
				return items;
			}, EMPTY_ARR),
		};
	}
	componentDidMount() {
		this.isMounted = true;
	}
	componentWillUnmount() {
		this.isMounted = false;
	}
	submitForm = () => {
		const { resortValue, resortIsValid } = this.state;
		if (resortIsValid && typeof window !== "undefined") {
			window.location.href = `https://${resortValue}.flaik.com/`;
		}
	};
	resetAllFields = () => {
		if (this.isMounted) {
			this.setState({
				resortValue: "",
				resortIsValid: false,
			});
		}
	};
	updateFieldValue = (fieldName, fieldValue, fieldIsValid) => {
		this.setState({
			[`${fieldName}Value`]: fieldValue,
			[`${fieldName}IsValid`]: fieldIsValid,
		});
	};
	render() {
		const { body, button, menu } = this.props;
		const { loginItems, resortValue, resortIsValid } = this.state;
		return (
			<>
				<div css={SharedStyles.stageHeader}>{menu}</div>
				<div css={SharedStyles.strapText}>{body}</div>
				<Form
					instructorLogin={this.submitForm}
					isValid={resortIsValid}
					onSuccess={this.resetAllFields}
					renderFields={({ isSubmitting }) => (
						<>
							<div css={SharedStyles.inputLabel}>
								Select your resort from the dropdown
							</div>
							<InputSelect
								isDisabled={isSubmitting}
								isRequired
								isValid={resortIsValid}
								items={loginItems}
								label="Select your resort"
								name="resort"
								onChange={this.updateFieldValue}
								value={resortValue}
							/>
						</>
					)}
					submitText={button}
					type={"instructorLogin"}
				/>
			</>
		);
	}
}

export default InstructorsLogin;
