import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 68 36">
		<path
			d="M59.42,19.36 C60.94,18.5 62,16.882 62,15 C62,12.24 59.76,10 57,10 C54.24,10 52,12.24 52,15 C52,16.882 53.06,18.5 54.58,19.36 C55.3,19.76 56.12,20 57,20 C57.88,20 58.7,19.76 59.42,19.36 M57,23 C53.32,23 46,25.02 46,29 L46,32 L68,32 L68,29 C68,25.02 60.68,23 57,23 M42.1,28 L33.54,28 C35.52,27 38.94,26 42,26 C42.22,26 42.46,26.02 42.68,26.02 C43.36,24.562 44.54,23.36 45.96,22.402 C44.5,22.142 43.12,22 42,22 C37.32,22 28,24.34 28,29 L28,32 L42,32 L42,29 C42,28.66 42.04,28.32 42.1,28 M42,8 C43.66,8 45,9.34 45,11 C45,12.66 43.66,14 42,14 C40.34,14 39,12.66 39,11 C39,9.34 40.34,8 42,8 M42,18 C45.86,18 49,14.86 49,11 C49,7.142 45.86,4 42,4 C38.14,4 35,7.142 35,11 C35,14.86 38.14,18 42,18"
			fill={styling.colors.primary}
		/>
		<path
			d="M22,10 L22,20 L30.5,25.04 L32.04,22.48 L25,18.298 L25,10 L22,10 Z M24,0 C14.06,0 6,8.06 6,18 L0,18 L8,25.98 L16,18 L10,18 C10,10.26 16.26,4 24,4 C31.74,4 38,10.26 38,18 C38,25.74 31.74,32 24,32 C20.14,32 16.64,30.42 14.12,27.878 L11.28,30.718 C14.54,33.98 19.02,36 24,36 C33.94,36 42,27.94 42,18 C42,8.06 33.94,0 24,0 L24,0 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
