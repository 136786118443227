// @flow

import style from "./style-mixins";
import colors from "./colors";
import sizes from "./sizes";
import utils from "./utils";

export default {
	mixins: style,
	colors,
	fontFamily: "'Circular', 'Helvetica Neue', helvetica, arial, sans-serif",
	sizes,
	utils,
	reset: {
		/* http://meyerweb.com/eric/tools/css/reset/
			v2.0 | 20110126
			License: none (public domain)
		*/
		[`html, body, div, span, applet, object, iframe,
	 	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	 	a, abbr, acronym, address, big, cite, code,
	 	del, dfn, em, img, ins, kbd, q, s, samp,
	 	small, strike, strong, sub, sup, tt, var,
	 	b, u, i, center,
	 	dl, dt, dd, ol, ul, li,
	 	fieldset, form, label, legend,
	 	table, caption, tbody, tfoot, thead, tr, th, td,
	 	article, aside, canvas, details, embed, 
	 	figure, figcaption, footer, header, hgroup, 
	 	menu, nav, output, ruby, section, summary,
	 	time, mark, audio, video`]: {
			margin: 0,
			padding: 0,
			border: 0,
			font: "inherit",
			verticalAlign: "baseline",
		},
		/* HTML5 displayRole reset for older browsers */
		[`article, aside, details, figcaption, figure, 
	 	footer, header, hgroup, menu, nav, section`]: {
			display: "block",
		},
		body: {
			lineHeight: 1,
			WebkitOverflowScrolling: "touch",
		},
		"ol, ul": {
			listStyle: "none",
		},
		"blockquote, q": {
			quotes: "none",
		},
		[`blockquote:before, blockquote:after,
	 	q:before, q:after`]: {
			content: '""',
		},
		table: {
			borderCollapse: "collapse",
			borderSpacing: 0,
		},
		input: {
			fontFamily: "'Circular', 'Helvetica Neue', helvetica, arial, sans-serif",
			...style.padding(0),
			...style.margin(0),
		},
		a: {
			textDecoration: "none",
		},
		button: {
			backgroundColor: colors.transparent,
		},
		strong: {
			fontWeight: 700,
		},
		em: {
			fontStyle: "italic",
		},
		u: {
			textDecoration: "underline",
		},
		"h1, h2, h3, h4, h5, h6": {
			color: colors.textHeaderDefault,
		},
		h2: {
			fontSize: "4rem",
			lineHeight: "4rem",
			fontWeight: 700,
			...style.margin(0, 0, 20),
			[sizes.breakpoints.medium]: {
				fontSize: "7.6rem",
				lineHeight: "7.6rem",
				...style.margin(0, 0, 40),
			},
		},
		h3: {
			fontSize: "2rem",
			lineHeight: "2.4rem",
			...style.margin(0, 0, 10),
			[sizes.breakpoints.medium]: {
				fontSize: "4rem",
				lineHeight: "4.8rem",
				...style.margin(0, 0, 30),
			},
		},
		h4: {
			fontSize: "1.6rem",
			lineHeight: "1.6rem",
			fontWeight: 700,
			...style.margin(0, 0, 5),
			[sizes.breakpoints.medium]: {
				...style.margin(0, 0, 20),
			},
		},
	},
	zIndex: {
		scrollDownPrompt: 20,
		header: 40,
		modal: 100,
		modalBG: 99,
	},
};
