import format from "date-fns/format";
import { bool, func, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { LoginResortsPropTypes } from "~/types";
import Form from "Components/Form";
import { Messages } from "Components/Icons";
import InputDate from "Components/InputDate";
import InputEmail from "Components/InputEmail";
import InputSelect from "Components/InputSelect";
import InputTag from "Components/InputTag";
import InputText from "Components/InputText";
import InputTextarea from "Components/InputTextarea";
import { EMPTY_ARR } from "Services/constants/empty";
import flaikRequest from "Services/flaik-request";
import Styles from "./styles.css";

class ForgotYourDetails extends React.Component {
	isMounted;
	todayDate;
	static propTypes = {
		body: string,
		button: string,
		lang: string,
		loginResorts: LoginResortsPropTypes,
		menu: string,
		hasFormResponse: bool,
		reset: func,
		thankYou: string,
	};
	constructor(props) {
		super(props);
		this.todayDate = new Date().setHours(0, 0, 0, 0);
		this.state = {
			tagValue: "",
			tagIsValid: false,
			lessonDateValue: format(this.todayDate, "yyyy-MM-dd"),
			lessonDateIsValid: true,
			fullNameValue: "",
			fullNameIsValid: false,
			emailValue: "",
			emailIsValid: false,
			resortValue: "",
			resortIsValid: false,
			instructorValue: "",
			liftPassValue: "",
			messageValue: "",
			loginItems: props.loginResorts.reduce((items, node) => {
				if (node.node) {
					return items.concat({
						title: node.node.title,
						value: node.node.slug,
					});
				}
				return items;
			}, EMPTY_ARR),
		};
	}
	componentDidMount() {
		this.isMounted = true;
	}
	componentWillUnmount() {
		this.isMounted = false;
	}
	submitForm = (additionalData) => {
		const {
			tagValue,
			lessonDateValue,
			fullNameValue,
			emailValue,
			resortValue,
			instructorValue,
			liftPassValue,
			messageValue,
		} = this.state;
		// forgot your details ajax
		return flaikRequest({
			data: {
				flaikSupport: {
					fullName: fullNameValue,
					email: emailValue,
					resortName: resortValue,
					lessonDate: lessonDateValue,
					instructorName: instructorValue,
					tagId: tagValue,
					liftPass: liftPassValue,
					message: messageValue,
					...additionalData,
				},
			},
		});
	};
	resetAllFields = () => {
		if (this.isMounted) {
			this.setState({
				tagValue: "",
				tagIsValid: false,
				lessonDateValue: format(this.todayDate, "yyyy-MM-dd"),
				lessonDateIsValid: true,
				fullNameValue: "",
				fullNameIsValid: false,
				emailValue: "",
				emailIsValid: false,
				resortValue: "",
				resortIsValid: false,
				instructorValue: "",
				liftPassValue: "",
				messageValue: "",
			});
		}
	};
	updateFieldValue = (fieldName, fieldValue, fieldIsValid) => {
		const { hasFormResponse, reset } = this.props;
		if (hasFormResponse) {
			reset();
		}
		this.setState({
			[`${fieldName}Value`]: fieldValue,
			[`${fieldName}IsValid`]: fieldIsValid,
		});
	};
	render() {
		const { body, button, lang, menu, thankYou } = this.props;
		const {
			tagValue,
			tagIsValid,
			lessonDateValue,
			lessonDateIsValid,
			fullNameValue,
			fullNameIsValid,
			emailValue,
			emailIsValid,
			resortValue,
			resortIsValid,
			instructorValue,
			liftPassValue,
			messageValue,
			loginItems,
		} = this.state;
		return (
			<>
				<div css={Styles.icon}>
					<Messages />
				</div>
				<div css={Styles.title}>{menu}</div>
				<div css={Styles.strapText}>{body}</div>
				<Form
					hasCaptcha
					isValid={
						fullNameIsValid &&
						emailIsValid &&
						resortIsValid &&
						lessonDateIsValid &&
						tagIsValid
					}
					onSuccess={this.resetAllFields}
					forgotYourDetails={this.submitForm}
					renderFields={({ isSubmitting }) => (
						<>
							<div css={Styles.inputLabel}>Full name</div>
							<InputText
								isDisabled={isSubmitting}
								isRequired
								isValid={fullNameIsValid}
								label="Your name"
								name="fullName"
								onChange={this.updateFieldValue}
								value={fullNameValue}
							/>
							<div css={Styles.inputLabel}>Email</div>
							<InputEmail
								isDisabled={isSubmitting}
								isRequired
								isValid={emailIsValid}
								label="yourname@email.com"
								name="email"
								onChange={this.updateFieldValue}
								value={emailValue}
							/>
							<div css={Styles.inputLabel}>
								Resort name where you had your lesson
							</div>
							<InputSelect
								isDisabled={isSubmitting}
								isRequired
								isValid={resortIsValid}
								items={loginItems}
								label="Resort name"
								name="resort"
								onChange={this.updateFieldValue}
								value={resortValue}
							/>
							<div css={Styles.inputLabel}>Date you had your lesson</div>
							<InputDate
								isDisabled={isSubmitting}
								isValid={lessonDateIsValid}
								label="Lesson date"
								lang={lang}
								max={format(this.todayDate, "yyyy-MM-dd")}
								name="lessonDate"
								onChange={this.updateFieldValue}
								value={lessonDateValue}
							/>
							<div css={Styles.inputLabel}>Instructor’s name</div>
							<InputText
								isDisabled={isSubmitting}
								label="Instructor"
								name="instructor"
								onChange={this.updateFieldValue}
								value={instructorValue}
							/>
							<div css={Styles.inputLabel}>Tag ID</div>
							<InputTag
								isDisabled={isSubmitting}
								isRequired
								isValid={tagIsValid}
								label="eg SCART-1"
								name="tag"
								onChange={this.updateFieldValue}
								value={tagValue}
							/>
							<div css={Styles.inputLabel}>Liftpass number</div>
							<InputText
								isDisabled={isSubmitting}
								label="1234567890"
								name="liftPass"
								onChange={this.updateFieldValue}
								value={liftPassValue}
							/>
							<div css={Styles.inputLabel}>Message</div>
							<InputTextarea
								isDisabled={isSubmitting}
								label="Message"
								name="message"
								onChange={this.updateFieldValue}
								value={messageValue}
							/>
						</>
					)}
					submitText={button}
					successMessage={thankYou}
					type={"forgotYourDetails"}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	hasFormResponse: state.formResponse.key !== "",
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	reset: () =>
		dispatch({
			type: "FORM_SUBMIT_RESET",
			payload: {
				formKey: null,
			},
		}),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ForgotYourDetails);
