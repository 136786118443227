import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 72 44">
		<path
			d="M52.0059675,27.7 C48.7839675,27.7 46.1419675,25.134 46.1419675,22 C46.1419675,18.866 48.7839675,16.3 52.0059675,16.3 C55.2279675,16.3 57.8679675,18.866 57.8679675,22 C57.8679675,25.134 55.2279675,27.7 52.0059675,27.7 L52.0059675,27.7 Z M67.2619675,23.932 C67.3659675,23.3 67.3999675,22.634 67.3999675,22 C67.3999675,21.334 67.3299675,20.7 67.2619675,20.034 L71.6159675,16.734 C71.9939675,16.432 72.1299675,15.866 71.8559675,15.434 L67.7419675,8.534 C67.4679675,8.1 66.9539675,7.934 66.4739675,8.1 L61.3659675,10.1 C60.3019675,9.3 59.1719675,8.634 57.9019675,8.134 L57.1479675,2.832 C57.0459675,2.366 56.6339675,2 56.1199675,2 L47.8919675,2 C47.3779675,2 46.9659675,2.366 46.8639675,2.866 L46.1079675,8.166 C44.8759675,8.666 43.6739675,9.3 42.6459675,10.1 L37.5379675,8.1 C37.0579675,7.934 36.5439675,8.1 36.2699675,8.534 L32.1559675,15.434 C31.8799675,15.866 32.0179675,16.402 32.3959675,16.734 L36.7499675,20.034 C36.6459675,20.7 36.5779675,21.334 36.5779675,22 C36.5779675,22.666 36.6459675,23.3 36.7139675,23.968 L32.3599675,27.266 C31.9839675,27.6 31.8799675,28.134 32.1559675,28.568 L36.2699675,35.466 C36.5439675,35.9 37.0579675,36.068 37.5379675,35.9 L42.6459675,33.902 C43.7099675,34.7 44.8399675,35.366 46.1079675,35.866 L46.8639675,41.166 C46.9659675,41.634 47.3779675,42 47.8919675,42 L56.1199675,42 C56.6339675,42 57.0459675,41.634 57.1479675,41.134 L57.9019675,35.832 C59.1359675,35.332 60.3019675,34.666 61.3659675,33.866 L66.4739675,35.866 C66.9539675,36.034 67.4679675,35.866 67.7419675,35.434 L71.8559675,28.534 C72.1299675,28.1 71.9939675,27.568 71.6159675,27.234 L67.2619675,23.932 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M13.9987146,20.0031935 L13.9987146,28.0154828 L18.0012062,28.0154828 L18.0012062,22.0062658 L26.0061895,22.0062658 L26.0061895,27.0139466 L33.0105499,20.0031935 L26.0061895,12.9924403 L26.0061895,18.0001212 L15.9999604,18.0001212 C14.8992752,18.0001212 13.9987146,18.9015037 13.9987146,20.0031935 L13.9987146,20.0031935 Z M22.0237103,40.0138861 L4.01249788,21.9862351 L22.0237103,3.95858413 L40.0349228,21.9862351 L22.0237103,40.0138861 Z M24.8454669,1.13425214 C23.3445326,-0.368052105 20.7028881,-0.388082828 19.1819413,1.13425214 L1.17072881,19.1619031 C-0.390242936,20.7242995 -0.390242936,23.2481707 1.17072881,24.8105671 L19.1819413,42.838218 C19.9624271,43.6194163 20.9830625,44 22.0036979,44 C23.0243332,44 24.0449686,43.6194163 24.8254545,42.838218 L42.8166545,24.8305978 C44.3776262,23.3082628 44.3976387,20.764361 42.8566794,19.1819338 L24.8454669,1.13425214 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
