import { EMPTY_ARR } from "Services/constants/empty";
import styling from "Services/styling";

export default {
	wrapper: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		fontSize: "1.6rem",
		lineHeight: "2rem",
		textAlign: "left",
		display: "flex",
		flexDirection: "column",
	},
	activity: {
		position: "relative",
		backgroundColor: styling.colors.primary,
		color: styling.colors.textOnPrimary,
		fontSize: "1.8rem",
		lineHeight: "2rem",
		fontWeight: 700,
		width: 200,
		display: "block",
		textAlign: "center",
		cursor: "pointer",
		...styling.mixins.borderRadius(8),
		...styling.mixins.padding(12),
		...styling.mixins.transition("opacity"),
		...styling.mixins.margin(10, "auto"),
	},
	reset: {
		color: styling.colors.textQuadrinary,
		fontSize: "1.6rem",
		lineHeight: "2.4rem",
		cursor: "pointer",
		...styling.mixins.border(EMPTY_ARR, EMPTY_ARR, [
			1,
			"dashed",
			styling.colors.textQuadrinary,
		]),
		...styling.mixins.margin("auto", "auto", 0),
		...styling.mixins.transitionMulti(["border-color"], ["color"]),
		":hover": {
			color: styling.colors.linkInteract,
			borderColor: styling.colors.linkInteract,
		},
	},
};
