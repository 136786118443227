// const enUS = require("date-fns/locale/en-US");
// const fr = require("date-fns/locale/fr");
//
// module.exports = {
// 	langs: ["en", "fr"],
// 	langTitles: ["English", "Français"],
// 	defaultLangKey: "en",
// 	dateLangs: {
// 		en: enUS,
// 		fr,
// 	},
// };
const enUS = require("date-fns/locale/en-US");

module.exports = {
	langs: ["en"],
	langTitles: ["English"],
	defaultLangKey: "en",
	dateLangs: {
		en: enUS,
	},
};
