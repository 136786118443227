import { keyframes } from "@emotion/core";
import React from "react";

import styling from "Services/styling";
import withDisplayAware from "Services/withDisplayAware";

const spinKeyframes = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

const wrapperStyle = (width, height) => ({
	position: "absolute",
	zIndex: -2,
	top: 0,
	left: 0,
	width: "100%",
	height,
	overflow: width >= height ? "visible" : "hidden",
});

const wobbleStyle = (width, height) => {
	const isLandscape = width >= height;
	return {
		position: "absolute",
		top: "50%",
		left: "50%",
		width: (isLandscape ? width : height) * 0.9,
		height: (isLandscape ? width : height) * 0.9,
		...styling.mixins.translate("-50%", "-50%"),
		"> svg": {
			width: isLandscape ? "100%" : void 0,
			height: isLandscape ? void 0 : "100%",
			...styling.mixins.transformOrigin("50%", "50%"),
			...styling.mixins.animation(spinKeyframes, 30, "linear", "infinite"),
		},
	};
};

const Wobble = ({ clientWidth, clientHeight }) => (
	<div css={wrapperStyle(clientWidth, clientHeight)}>
		<div css={wobbleStyle(clientWidth, clientHeight)}>
			<svg viewBox="0 0 1442 1442">
				<path
					d="M721,1441.5 C499.941046,1441.5 293.904445,1352.25638 162.6123,1198.34491 C57.7124236,1075.37262 0.5,903.762437 0.5,721 C0.5,625.777433 23.4694437,535.77331 67.3797503,449.940282 C106.523594,373.424427 161.869136,300.952135 232.401604,230.659005 C302.683661,160.615437 374.911307,105.72371 451.092201,66.8924722 C536.617672,23.2980779 626.214534,0.5 721,0.5 C866.931842,0.5 990.162481,43.2771595 1115.54145,131.625499 C1210.05601,198.225221 1290.70861,285.251017 1347.91025,383.846827 C1408.74654,488.70751 1441.5,603.606176 1441.5,721 C1441.5,941.786288 1365.54452,1145.65983 1228.25555,1271.76186 C1107.23973,1382.91675 922.640253,1441.5 721,1441.5 Z"
					fill="none"
					stroke={styling.colors.ripple}
				/>
			</svg>
		</div>
	</div>
);

export default withDisplayAware("clientWidth", "clientHeight")(Wobble);
