import styling from "Services/styling";

export default {
	button: (isDisabled) => ({
		position: "relative",
		backgroundColor: styling.colors.primary,
		color: styling.colors.textOnPrimary,
		opacity: isDisabled ? 0.4 : 1,
		fontSize: "2rem",
		lineHeight: "2.4rem",
		fontWeight: 700,
		width: "100%",
		display: "block",
		textAlign: "center",
		cursor: isDisabled ? "default" : "pointer",
		...styling.mixins.borderRadius(8),
		...styling.mixins.padding(18, 30),
		...styling.mixins.transition("opacity"),
		[styling.sizes.breakpoints.medium]: {
			display: "inline-block",
			...styling.mixins.margin(0, "auto"),
		},
	}),
	loadingOverlay: (isLoading) => ({
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		opacity: isLoading ? 1 : 0,
		...styling.mixins.transition("opacity"),
	}),
	children: (isLoading) => ({
		opacity: isLoading ? 0 : 1,
		...styling.mixins.transition("opacity"),
	}),
	spinnerColor: styling.colors.textOnPrimary,
};
