import React from "react";

import Arrow from "Components/Arrow";
import styling from "Services/styling";
import withDisplayAware from "Services/withDisplayAware";

const arrowWrapper = {
	position: "absolute",
	top: "100%",
	left: 0,
	right: 0,
	height: 0,
	textAlign: "center",
	verticalAlign: "bottom",
	display: "none",
	zIndex: styling.zIndex.scrollDownPrompt,
	...styling.mixins.margin(-50, 0, 0),
	":hover path": {
		fill: styling.colors.linkInteract,
	},
	[styling.sizes.breakpoints.medium]: {
		display: "block",
	},
};

const arrowStyles = (isAtTop) => ({
	opacity: isAtTop ? 1 : 0,
	pointerEvents: isAtTop ? "all" : "none",
	cursor: "pointer",
	...styling.mixins.transition("opacity"),
});

const ScrollDownPrompt = ({ scrollTop, clientHeight }) => (
	<div
		css={arrowWrapper}
		onClick={() => {
			if (typeof window === "undefined") {
				return;
			}
			window.scrollTo({
				top: clientHeight,
				left: 0,
				behavior: "smooth",
			});
		}}>
		<Arrow direction="down" style={arrowStyles(scrollTop < clientHeight / 4)} />
	</div>
);

export default withDisplayAware("scrollTop", "clientHeight")(ScrollDownPrompt);
