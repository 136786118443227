import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 50 50">
		<path
			d="M46,42 C48.2,42 50,40.2 50,38 L50,14 C50,11.8 48.2,10 46,10 L14,10 C11.8,10 10.02,11.8 10.02,14 L10,50 L18,42 L46,42 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M8,12 L32,12 L32,8 L8,8 L8,12 Z M8,18 L32,18 L32,14 L8,14 L8,18 Z M8,24 L24,24 L24,20 L8,20 L8,24 Z M4,30.34 L4,4 L36,4 L36,28 L6.34,28 L4,30.34 Z M36,32 C38.2,32 40,30.2 40,28 L40,4 C40,1.8 38.2,0 36,0 L4,0 C1.8,0 0.02,1.8 0.02,4 L0,40 L8,32 L36,32 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
