export default {
	scrollTop: (scrollTop) => {
		if (typeof window === "undefined") {
			return void 0;
		}
		if (typeof scrollTop !== "undefined") {
			if (typeof window.document.documentElement !== "undefined") {
				window.document.documentElement.scrollTop = scrollTop;
			}
			window.document.body.scrollTop = scrollTop;
		} else {
			if (typeof window.document.documentElement !== "undefined") {
				return Math.max(
					window.document.documentElement.scrollTop,
					window.document.body.scrollTop
				);
			}
			return window.document.body.scrollTop;
		}
		return void 0;
	},
};
