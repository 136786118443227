import { bool, func, string, oneOfType } from "prop-types";
import * as React from "react";
import { connect } from "react-redux";

import Button from "Components/Button";
import LoadingScreen from "Components/LoadingScreen";
import Recaptcha from "Components/Recaptcha";
import { EMPTY_OBJ, NOOP } from "Services/constants/empty";
import { isFunction } from "Services/helper-utils";
import logger from "Services/logger";
import FormMessage from "./elements/FormMessage";
import Styles from "./styles.css";

const submitActions = {
	forgotYourDetails: "forgotYourDetails",
	instructorLogin: "instructorLogin",
	reliveMyDay: "reliveMyDay",
	requestDemo: "requestDemo",
};

class Form extends React.Component {
	static propTypes = {
		formSubmit: func,
		hasCaptcha: bool.isRequired,
		isComplete: bool.isRequired,
		isValid: bool.isRequired,
		onSuccess: func,
		recaptchaKey: string,
		renderFields: func.isRequired,
		submitText: string.isRequired,
		successMessage: oneOfType([string, func]),
		type: string.isRequired,
	};
	static defaultProps = {
		hasCaptcha: false,
		onSuccess: NOOP,
	};
	constructor(props) {
		super(props);
		this.state = {
			isLoading: props.hasCaptcha,
			isSubmitting: false,
			response: null,
			recaptchaResponse: null,
		};
	}
	recaptchaInstance = null;
	recaptchaLoaded = () => {
		this.setState({
			isLoading: false,
		});
	};
	executeCaptcha = () => {
		if (this.recaptchaInstance) {
			this.recaptchaInstance.execute();
		} else {
			logger("error", "[RECAPTCHA] Instance not found");
		}
	};
	expiredCallback = () => {
		if (this.recaptchaInstance) {
			this.setState({
				recaptchaResponse: null,
			});
			this.recaptchaInstance.reset();
		}
	};
	verifyCallback = (response) => {
		this.setState({
			recaptchaResponse: response,
		});
	};
	handleOnClick = () => {
		const { hasCaptcha, isValid } = this.props;
		const { isSubmitting, recaptchaResponse } = this.state;
		if (!isValid || isSubmitting || (hasCaptcha && recaptchaResponse === null))
			return;
		this.setState({ isSubmitting: true });
		if (hasCaptcha) {
			this.handleSubmit({
				"g-recaptcha-response": recaptchaResponse,
			});
		} else {
			this.handleSubmit();
		}
	};
	handleSubmit = (additionalData = EMPTY_OBJ) => {
		const { formSubmit, onSuccess, type } = this.props;
		if (submitActions[type] && this.props[submitActions[type]]) {
			if (typeof window !== "undefined" && window.ga) {
				window.ga(`send`, `event`, {
					eventCategory: `Form submission`,
					eventAction: `submit`,
					eventLabel: type,
				});
			}
			const submitFn = this.props[submitActions[type]](additionalData);
			if (submitFn && submitFn.then && isFunction(submitFn.then)) {
				submitFn.then((response) => {
					formSubmit(response);
					if (response && response.code === 200) {
						onSuccess();
					}
					this.setState(
						{
							isSubmitting: false,
						},
						this.expiredCallback
					);
				});
			} else {
				this.setState(
					{
						isSubmitting: false,
					},
					this.expiredCallback
				);
				onSuccess();
			}
		} else {
			this.setState(
				{
					isSubmitting: false,
				},
				this.expiredCallback
			);
		}
	};
	renderSubmitButton = () => {
		const {
			hasCaptcha,
			isComplete,
			isValid,
			submitText,
			successMessage,
			type,
		} = this.props;
		const { isSubmitting, recaptchaResponse } = this.state;
		return (
			<>
				<FormMessage successMessage={successMessage} type={type} />
				{!isComplete && (
					<Button
						isDisabled={!isValid || (hasCaptcha && recaptchaResponse === null)}
						isLoading={isSubmitting}
						onClick={this.handleOnClick}
						onKeyPress={(e) => {
							switch (e.key) {
								case " ": // space
								case "Enter": // return
									this.handleOnClick();
									e.preventDefault();
									break;
								default:
							}
						}}
						tabIndex={1}>
						{submitText}
					</Button>
				)}
			</>
		);
	};
	render() {
		const { hasCaptcha, isComplete, renderFields } = this.props;
		const { isLoading, isSubmitting } = this.state;
		return (
			<form
				css={Styles.wrapper}
				onSubmit={(e) => {
					this.handleOnClick();
					e.preventDefault();
					return;
				}}>
				{isLoading ? (
					<LoadingScreen backgroundColor={Styles.backgroundColor} />
				) : (
					renderFields({ isSubmitting: isSubmitting || isComplete })
				)}
				{hasCaptcha && (
					<Recaptcha
						badge="inline"
						expiredCallback={this.expiredCallback}
						onloadCallback={this.recaptchaLoaded}
						setRef={(e) => {
							this.recaptchaInstance = e;
						}}
						verifyCallback={this.verifyCallback}
					/>
				)}
				{!isLoading && this.renderSubmitButton()}
			</form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	isComplete: state.completedForms.includes(ownProps.type),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	formSubmit: (response) =>
		dispatch({
			type: "FORM_SUBMIT_RESPONSE",
			payload: {
				formKey: ownProps.type,
				response,
			},
		}),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form);
