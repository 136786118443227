import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 73 40">
		<path
			d="M66.507112,29.4411677 L51.605464,14.5836555 C53.1334168,10.750093 52.344796,6.22681691 49.2396014,3.11573348 C45.4607932,-0.652298058 39.5789961,-0.996335719 35.3894479,2.04921673 L41.681985,8.32380837 L39.3489817,10.6501583 L33.0564446,4.37556663 C30.0005388,8.53678407 30.3455604,14.4018071 34.1243686,18.1698386 C37.1802744,21.2170293 41.6326962,22.0214221 45.4443636,20.5961233 L60.41173,35.5208047 C61.0524845,36.1597318 62.0875493,36.1597318 62.7283038,35.5208047 L66.507112,31.7511349 C67.164296,31.1285905 67.164296,30.0817331 66.507112,29.4411677"
			fill={styling.colors.primary}
		/>
		<path
			d="M19,8 L16,8 L16,20 L25.5,25.7 L27,23.238 L19,18.5 L19,8 Z M18,32 C10.26,32 4,25.738 4,18 C4,10.26 10.26,4 18,4 C25.74,4 32,10.26 32,18 C32,25.738 25.74,32 18,32 L18,32 Z M18,0 C8.06,0 0,8.058 0,18 C0,27.94 8.04,36 18,36 C27.94,36 36,27.94 36,18 C36,8.058 27.94,0 18,0 L18,0 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
