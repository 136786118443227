import { Link } from "gatsby";
import { arrayOf, string, func } from "prop-types";
import React from "react";

import {
	HeaderPropTypes,
	LoginResortsPropTypes,
	MenuItemPropType,
} from "~/types";
import Arrow from "Components/Arrow";
import Brand from "Components/Brand";
import { Mortars, ShadowShadowAlt, Times } from "Components/Icons";
import { EMPTY_ARR } from "Services/constants/empty";
import ForgotYourDetails from "./elements/ForgotYourDetails";
import InstructorsLogin from "./elements/InstructorsLogin";
import ResortGuests from "./elements/ResortGuests";
import Styles from "./styles.css";

class MobileMenu extends React.Component {
	static propTypes = {
		header: HeaderPropTypes,
		lang: string,
		loginResorts: LoginResortsPropTypes,
		products: arrayOf(MenuItemPropType),
		productsMenu: string,
		setMobileMenuOpen: func,
		signInMenu: string,
	};
	constructor(props) {
		super(props);
		this.state = {
			stagesInView: EMPTY_ARR,
		};
	}
	addStage = (stageName) => {
		this.setState((prevState) => ({
			stagesInView: prevState.stagesInView.concat(stageName),
		}));
	};
	removeStage = (stageName) => {
		this.setState((prevState) => ({
			stagesInView: prevState.stagesInView.filter(
				(stage) => stage !== stageName
			),
		}));
	};
	render() {
		const {
			setMobileMenuOpen,
			productsMenu,
			signInMenu,
			header,
			lang,
			loginResorts,
			products,
		} = this.props;
		const { stagesInView } = this.state;
		return (
			<>
				{/* main menu page */}
				<div css={Styles.stage(true)}>
					<div css={Styles.brand}>
						<Link to={`/${lang}/`}>
							<Brand />
						</Link>
					</div>
					<div css={Styles.closeIcon} onClick={() => setMobileMenuOpen(false)}>
						<Times />
					</div>
					<div css={Styles.stageContent}>
						<div
							css={Styles.stageLink}
							onClick={() => this.addStage("products")}>
							{productsMenu}
							<span css={Styles.stageLinkCaret(26)} />
						</div>
						<div css={Styles.stageLink} onClick={() => this.addStage("signIn")}>
							{signInMenu}
							<span css={Styles.stageLinkCaret(26)} />
						</div>
					</div>
				</div>
				{/* products */}
				<div css={Styles.stage(stagesInView.includes("products"))}>
					<div css={Styles.back} onClick={() => this.removeStage("products")}>
						<Arrow direction={"left"} style={Styles.backArrow} />
						{header.backButton}
					</div>
					<div css={Styles.closeIcon} onClick={() => setMobileMenuOpen(false)}>
						<Times />
					</div>
					<div css={Styles.stageContent}>
						<div css={Styles.stageHeader}>{productsMenu}</div>
						<nav>
							{products.map((productItem) => (
								<Link
									key={productItem.node.slug}
									activeStyle={Styles.menuLinkActive}
									css={Styles.menuLink}
									onClick={() => setMobileMenuOpen(false)}
									to={`/${lang}/product/${productItem.node.slug}/`}>
									{productItem.node.title}
								</Link>
							))}
						</nav>
					</div>
				</div>
				{/* sign in */}
				<div css={Styles.stage(stagesInView.includes("signIn"))}>
					<div css={Styles.back} onClick={() => this.removeStage("signIn")}>
						<Arrow direction={"left"} style={Styles.backArrow} />
						{header.backButton}
					</div>
					<div css={Styles.closeIcon} onClick={() => setMobileMenuOpen(false)}>
						<Times />
					</div>
					<div css={Styles.stageContent}>
						<div css={Styles.stageHeader}>{signInMenu}</div>
						<div
							css={Styles.signInSection}
							onClick={() => this.addStage("resortGuests")}>
							<span css={Styles.stageLinkCaret(76)} />
							<div css={Styles.signInIcon}>
								<ShadowShadowAlt />
							</div>
							<div css={Styles.signInTitle}>{header.resortGuestsMenu}</div>
							<div css={Styles.strapText}>{header.resortGuestsStrap}</div>
						</div>
						<div css={Styles.signInSeperator} />
						<div
							css={Styles.signInSection}
							onClick={() => this.addStage("instructors")}>
							<span css={Styles.stageLinkCaret(76)} />
							<div css={Styles.signInIcon}>
								<Mortars />
							</div>
							<div css={Styles.signInTitle}>{header.instructorsMenu}</div>
							<div css={Styles.strapText}>{header.instructorsStrap}</div>
						</div>
					</div>
				</div>
				{/* resort guests */}
				<div css={Styles.stage(stagesInView.includes("resortGuests"))}>
					<div
						css={Styles.back}
						onClick={() => this.removeStage("resortGuests")}>
						<Arrow direction={"left"} style={Styles.backArrow} />
						{header.backButton}
					</div>
					<div css={Styles.closeIcon} onClick={() => setMobileMenuOpen(false)}>
						<Times />
					</div>
					<div css={Styles.stageContent}>
						<ResortGuests
							body={header.resortGuestsBody}
							button={header.resortGuestsButton}
							forgot={header.havingTroubleMenu}
							goToForgotStage={() => this.addStage("forgotYourDetails")}
							lang={lang}
							loginResorts={loginResorts}
							menu={header.resortGuestsMenu}
						/>
					</div>
				</div>
				{/* instructors */}
				<div css={Styles.stage(stagesInView.includes("instructors"))}>
					<div
						css={Styles.back}
						onClick={() => this.removeStage("instructors")}>
						<Arrow direction={"left"} style={Styles.backArrow} />
						{header.backButton}
					</div>
					<div css={Styles.closeIcon} onClick={() => setMobileMenuOpen(false)}>
						<Times />
					</div>
					<div css={Styles.stageContent}>
						<InstructorsLogin
							body={header.instructorsBody}
							button={header.instructorsButton}
							loginResorts={loginResorts}
							menu={header.instructorsMenu}
						/>
					</div>
				</div>
				{/* forgot your details */}
				<div css={Styles.stage(stagesInView.includes("forgotYourDetails"))}>
					<div
						css={Styles.back}
						onClick={() => this.removeStage("forgotYourDetails")}>
						<Arrow direction={"left"} style={Styles.backArrow} />
						{header.backButton}
					</div>
					<div css={Styles.closeIcon} onClick={() => setMobileMenuOpen(false)}>
						<Times />
					</div>
					<div css={Styles.stageContent}>
						<ForgotYourDetails
							body={header.havingTroubleBody}
							button={header.havingTroubleButton}
							lang={lang}
							loginResorts={loginResorts}
							menu={header.havingTroubleStrap}
							thankYou={header.havingTroubleThankYou}
						/>
					</div>
				</div>
			</>
		);
	}
}

export default MobileMenu;
