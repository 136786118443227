import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 69 36">
		<path
			d="M65,29.08 L59,31.1 L59,7.72 L65,5.4 L65,29.08 Z M55,31.06 L47,28.26 L47,4.94 L55,7.74 L55,31.06 Z M43,28.3 L37,30.622 L37,6.92 L43,4.9 L43,28.3 Z M68,0 L67.68,0.06 L57,4.2 L45,0 L33.72,3.8 C33.3,3.94 33,4.3 33,4.76 L33,35 C33,35.56 33.44,36 34,36 L34.32,35.94 L45,31.8 L57,36 L68.28,32.2 C68.7,32.06 69,31.7 69,31.24 L69,1 C69,0.44 68.56,0 68,0 L68,0 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M22,10 L22,20 L30.5,25.04 L32.04,22.48 L25,18.298 L25,10 L22,10 Z M24,0 C14.06,0 6,8.06 6,18 L0,18 L8,25.98 L16,18 L10,18 C10,10.26 16.26,4 24,4 C31.74,4 38,10.26 38,18 C38,25.74 31.74,32 24,32 C20.14,32 16.64,30.42 14.12,27.878 L11.28,30.718 C14.54,33.98 19.02,36 24,36 C33.94,36 42,27.94 42,18 C42,8.06 33.94,0 24,0 L24,0 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
