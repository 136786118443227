import { bool, func, string } from "prop-types";
import * as React from "react";

import { isPresent } from "Services/helper-utils";
import { EMPTY_ARR, NOOP } from "Services/constants/empty";
import DatePicker from "./elements/DatePicker";
import Styles from "./styles.css";

class InputDate extends React.Component {
	container;
	static propTypes = {
		isDisabled: bool,
		isRequired: bool,
		isValid: bool,
		label: string,
		lang: string,
		max: string,
		min: string,
		name: string,
		onChange: func,
		value: string,
	};
	static defaultProps = {
		valueFormatter: NOOP,
	};
	constructor(props) {
		super(props);
		this.state = {
			datePickerVisible: false,
			errors: this.validate(props.value, props.isRequired),
			isTouched: false,
		};
	}
	validate = (value, isRequired) => {
		if (isRequired && !isPresent(value)) {
			return ["Required"];
		}
		return EMPTY_ARR;
	};
	handleOnChange = (value) => {
		const { isDisabled, isRequired, name, onChange } = this.props;
		if (isDisabled) return;
		const errors = this.validate(value, isRequired);
		this.setState({
			errors,
			datePickerVisible: false,
		});
		onChange(name, value, errors.length === 0);
	};
	handleOnBlur = (e) => {
		if (!this.state.isTouched) {
			this.setState({
				isTouched: true,
			});
		}
		this.checkBlurIsntChildOfContainer(e);
	};
	showDatePicker = () => {
		this.setState({
			datePickerVisible: true,
		});
	};
	checkBlurIsntChildOfContainer = (e) => {
		if (
			e.relatedTarget === null ||
			(e.relatedTarget && !this.container.contains(e.relatedTarget))
		) {
			this.setState({
				datePickerVisible: false,
			});
		}
	};
	render() {
		const { isDisabled, isRequired, label, max, min, name, value } = this.props;
		const { datePickerVisible, errors, isTouched } = this.state;
		return (
			<div
				ref={(c) => {
					if (c) {
						this.container = c;
					}
				}}
				css={Styles.wrapper(isDisabled)}
				onBlur={this.handleOnBlur}>
				{isDisabled && <div css={Styles.inputCover} />}
				<input
					css={Styles.input(errors.length > 0, isTouched)}
					disabled={isDisabled}
					max={max}
					min={min}
					name={name}
					onChange={(e) => this.handleOnChange(e.target.value)}
					onFocus={this.showDatePicker}
					placeholder={isRequired ? `*${label}` : label}
					required={isRequired}
					tabIndex={1}
					type="date"
					value={value}
				/>
				{errors.length > 0 && (
					<div css={Styles.error(isTouched)}>{errors[0]}</div>
				)}
				{datePickerVisible && (
					<section css={Styles.datePicker} tabIndex={1}>
						<DatePicker
							handleOnChange={this.handleOnChange}
							max={max}
							min={min}
							value={value}
						/>
					</section>
				)}
			</div>
		);
	}
}

export default InputDate;
