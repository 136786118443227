import format from "date-fns/format";
import { bool, func, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { LoginResortsPropTypes } from "~/types";
import Form from "Components/Form";
import InputDate from "Components/InputDate";
import InputTag from "Components/InputTag";
import InputSelect from "Components/InputSelect";
import { EMPTY_ARR } from "Services/constants/empty";
import flaikRequest from "Services/flaik-request";
import SharedStyles from "../../styles.css";
import ReliveYourDaySuccess from "./elements/ReliveYourDaySuccess";
import Styles from "./styles.css";

class ResortGuests extends React.Component {
	isMounted;
	todayDate;
	static propTypes = {
		goToForgotStage: func,
		body: string,
		button: string,
		forgot: string,
		lang: string,
		menu: string,
		hasFormResponse: bool,
		loginResorts: LoginResortsPropTypes,
		reset: func,
	};
	constructor(props) {
		super(props);
		this.todayDate = new Date().setHours(0, 0, 0, 0);
		this.state = {
			guestResortValue: "",
			guestResortIsValid: false,
			tagValue: "",
			tagIsValid: false,
			lessonDateValue: format(this.todayDate, "yyyy-MM-dd"),
			lessonDateIsValid: true,
			loginItems: props.loginResorts.reduce((items, node) => {
				if (node.node) {
					return items.concat({
						title: node.node.title,
						value: node.node.slug,
					});
				}
				return items;
			}, EMPTY_ARR),
		};
	}
	componentDidMount() {
		this.isMounted = true;
	}
	componentWillUnmount() {
		this.isMounted = false;
	}
	submitForm = () => {
		const { tagValue, lessonDateValue, guestResortValue } = this.state;
		// relive my day ajax
		return flaikRequest({
			data: {
				flaikReliveYourDay: {
					tagId: tagValue,
					resort: guestResortValue,
					lessonDate: lessonDateValue,
				},
			},
		});
	};
	resetAllFields = () => {
		if (this.isMounted) {
			this.setState({
				tagValue: "",
				tagIsValid: false,
				lessonDateValue: format(this.todayDate, "yyyy-MM-dd"),
				lessonDateIsValid: true,
			});
		}
	};
	updateFieldValue = (fieldName, fieldValue, fieldIsValid) => {
		const { hasFormResponse, reset } = this.props;
		if (hasFormResponse) {
			reset();
		}
		this.setState({
			[`${fieldName}Value`]: fieldValue,
			[`${fieldName}IsValid`]: fieldIsValid,
		});
	};
	render() {
		const { goToForgotStage, body, button, forgot, lang, menu } = this.props;
		const {
			loginItems,
			tagValue,
			tagIsValid,
			lessonDateValue,
			lessonDateIsValid,
			guestResortIsValid,
			guestResortValue,
		} = this.state;
		return (
			<>
				<div css={SharedStyles.stageHeader}>{menu}</div>
				<div css={SharedStyles.strapText}>{body}</div>
				<Form
					isValid={tagIsValid && lessonDateIsValid && guestResortIsValid}
					reliveMyDay={this.submitForm}
					renderFields={({ isSubmitting }) => (
						<>
							<div css={SharedStyles.inputLabel}>
								Select a resort from the dropdown
							</div>
							<InputSelect
								isDisabled={isSubmitting}
								isRequired
								isValid={guestResortIsValid}
								items={loginItems}
								label="Select a resort"
								name="guestResort"
								onChange={this.updateFieldValue}
								value={guestResortValue}
							/>
							<div css={SharedStyles.inputLabel}>Enter your lesson Tag ID</div>
							<InputTag
								isDisabled={isSubmitting}
								isRequired
								isValid={tagIsValid}
								label="eg SCART-1"
								name="tag"
								onChange={this.updateFieldValue}
								value={tagValue}
							/>
							<div css={SharedStyles.inputLabel}>Select your lesson date</div>
							<InputDate
								isDisabled={isSubmitting}
								isRequired
								isValid={lessonDateIsValid}
								label="Lesson date"
								lang={lang}
								max={format(this.todayDate, "yyyy-MM-dd")}
								name="lessonDate"
								onChange={this.updateFieldValue}
								value={lessonDateValue}
							/>
						</>
					)}
					submitText={button}
					successMessage={() => <ReliveYourDaySuccess />}
					type={"reliveMyDay"}
				/>
				<div css={Styles.footer}>
					<span css={Styles.forgotLink} onClick={goToForgotStage}>
						{forgot}
					</span>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	hasFormResponse: state.formResponse.key !== "",
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	reset: () =>
		dispatch({
			type: "FORM_SUBMIT_RESET",
			payload: {
				formKey: null,
			},
		}),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ResortGuests);
