import { Link } from "gatsby";
import debounce from "lodash.debounce";
import { arrayOf, oneOf, string } from "prop-types";
import React from "react";

import {
	HeaderPropTypes,
	LoginResortsPropTypes,
	MenuItemPropType,
} from "~/types";
import Brand from "Components/Brand";
import constants from "Services/constants";
// import LanguageMenu from "./elements/LanguageMenu";
import MobileMenu from "./elements/MobileMenu";
import ProductMenu from "./elements/ProductMenu";
import SignInMenu from "./elements/SignInMenu";
import Styles from "./styles.css";

class Header extends React.Component {
	static propTypes = {
		lang: string,
		loginResorts: LoginResortsPropTypes,
		pageType: oneOf(["home", "plain", "product"]),
		products: arrayOf(MenuItemPropType),
		productsMenu: string,
		signInMenu: string,
		header: HeaderPropTypes,
	};
	constructor(props) {
		super(props);
		this.closingTimer = null;
		this.state = {
			isAtTop: constants.scrollTop() === 0,
			languageMenuIsOpen: false,
			mobileMenuIsOpen: false,
			productMenuIsOpen: false,
			signInMenuIsOpen: false,
		};
	}
	componentDidMount() {
		if (typeof window !== "undefined") {
			window.document.addEventListener("scroll", this.throttledScroll);
			window.document.addEventListener("touchmove", this.throttledScroll);
		}
	}
	componentWillUnmount() {
		if (typeof window !== "undefined") {
			window.document.removeEventListener("scroll", this.throttledScroll);
			window.document.removeEventListener("touchmove", this.throttledScroll);
		}
	}
	throttledScroll = debounce(() => {
		const { isAtTop } = this.state;
		const scrollTop = constants.scrollTop();
		if (isAtTop && scrollTop !== 0) {
			this.setState({
				isAtTop: false,
			});
		} else if (!isAtTop && scrollTop === 0) {
			this.setState({
				isAtTop: true,
			});
		}
	}, 100);
	setMobileMenuOpen = (isOpen) => {
		this.setState(
			{
				mobileMenuIsOpen: isOpen,
			},
			() => {
				if (typeof window !== "undefined") {
					if (isOpen) {
						window.document.body.classList.add("modal-open");
					} else {
						window.document.body.classList.remove("modal-open");
					}
				}
			}
		);
	};
	setLanguageMenuOpen = (isOpen) => {
		this.cancelCloseTimer();
		this.setState({
			languageMenuIsOpen: isOpen,
			productMenuIsOpen: false,
			signInMenuIsOpen: false,
		});
	};
	setProductMenuOpen = (isOpen) => {
		this.cancelCloseTimer();
		this.setState({
			productMenuIsOpen: isOpen,
			signInMenuIsOpen: false,
			languageMenuIsOpen: false,
		});
	};
	setSignInMenuOpen = (isOpen) => {
		this.cancelCloseTimer();
		this.setState({
			signInMenuIsOpen: isOpen,
			productMenuIsOpen: false,
			languageMenuIsOpen: false,
		});
	};
	cancelCloseTimer = () => {
		if (this.closingTimer && typeof window !== "undefined") {
			window.clearTimeout(this.closingTimer);
		}
	};
	timedCloseAllMenus = () => {
		if (typeof window !== "undefined") {
			this.closingTimer = window.setTimeout(this.closeAllMenus, 2000);
		}
	};
	closeAllMenus = () => {
		this.closingTimer = null;
		this.setState({
			languageMenuIsOpen: false,
			signInMenuIsOpen: false,
			productMenuIsOpen: false,
		});
	};
	render() {
		const {
			header,
			lang,
			loginResorts,
			pageType,
			products,
			productsMenu,
			signInMenu,
		} = this.props;
		const {
			isAtTop,
			// languageMenuIsOpen,
			mobileMenuIsOpen,
			productMenuIsOpen,
			signInMenuIsOpen,
		} = this.state;
		return (
			<div css={Styles.wrapper(isAtTop)}>
				<header css={Styles.header}>
					<div css={Styles.brand}>
						<Link css={Styles.brandLink} to={`/${lang}/`}>
							<Brand />
							<h1>flaik</h1>
						</Link>
					</div>
					<div css={Styles.menus}>
						<div css={Styles.mobileMenu}>
							{/* <div */}
							{/* 	css={Styles.languageTitle(languageMenuIsOpen)} */}
							{/* 	onClick={() => this.setLanguageMenuOpen(true)} */}
							{/* 	onMouseEnter={() => this.setLanguageMenuOpen(true)} */}
							{/* 	onMouseLeave={this.closeAllMenus}> */}
							{/* 	{lang.toUpperCase()} */}
							{/* 	{languageMenuIsOpen && <LanguageMenu lang={lang} />} */}
							{/* </div> */}
							<div
								css={Styles.hamburger}
								onClick={() => this.setMobileMenuOpen(true)}
							/>
						</div>
						<div
							css={Styles.menu}
							onMouseEnter={this.cancelCloseTimer}
							onMouseLeave={this.timedCloseAllMenus}>
							<div
								css={Styles.menuTitle(
									productMenuIsOpen || pageType === "product"
								)}
								onClick={() => this.setProductMenuOpen(true)}
								onMouseEnter={() => this.setProductMenuOpen(true)}>
								{productsMenu}
							</div>
							<div
								css={Styles.menuTitle(signInMenuIsOpen)}
								onClick={() => this.setSignInMenuOpen(true)}
								onMouseEnter={() => this.setSignInMenuOpen(true)}>
								{signInMenu}
							</div>
							{/* <div */}
							{/* 	css={Styles.languageTitle(languageMenuIsOpen)} */}
							{/* 	onClick={() => this.setLanguageMenuOpen(true)} */}
							{/* 	onMouseEnter={() => this.setLanguageMenuOpen(true)}> */}
							{/* 	{lang.toUpperCase()} */}
							{/* </div> */}
							{productMenuIsOpen && (
								<ProductMenu header={header} lang={lang} products={products} />
							)}
							{signInMenuIsOpen && (
								<SignInMenu
									header={header}
									lang={lang}
									loginResorts={loginResorts}
								/>
							)}
							{/* {languageMenuIsOpen && <LanguageMenu lang={lang} />} */}
						</div>
					</div>
					<div css={Styles.mobileFlyout(mobileMenuIsOpen)}>
						{mobileMenuIsOpen && (
							<MobileMenu
								header={header}
								lang={lang}
								loginResorts={loginResorts}
								products={products}
								productsMenu={productsMenu}
								setMobileMenuOpen={this.setMobileMenuOpen}
								signInMenu={signInMenu}
							/>
						)}
					</div>
				</header>
			</div>
		);
	}
}

export default Header;
