import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 52 39">
		<path
			d="M47.9402,17 C47.9402,10.86 44.6802,5.72 38.9402,4.36 L38.9402,3 C38.9402,1.34 37.6002,0 35.9402,0 C34.2802,0 32.9402,1.34 32.9402,3 L32.9402,4.36 C27.2202,5.72 23.9402,10.84 23.9402,17 L23.9402,27 L19.9402,31 L19.9402,33 L51.9402,33 L51.9402,31 L47.9402,27 L47.9402,17 Z M35.9402,39 C38.1402,39 39.9402,37.2 39.9402,35 L31.9402,35 C31.9402,37.2 33.7402,39 35.9402,39 L35.9402,39 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M35.8796,16 L39.8796,16 C39.5796,9.6 36.4196,3.96 31.6396,0.3 L28.7996,3.16 C32.8396,6.06 35.5796,10.7 35.8796,16 L35.8796,16 Z M11.0996,3.16 L8.2396,0.3 C3.4396,3.96 0.2796,9.6 -0.0004,16 L3.9996,16 C4.2996,10.7 7.0196,6.06 11.0996,3.16 L11.0996,3.16 Z M27.9396,29 L11.9396,29 L11.9396,17 C11.9396,12.04 14.9596,8 19.9396,8 C24.9196,8 27.9396,12.04 27.9396,17 L27.9396,29 Z M31.9396,17 C31.9396,10.86 28.6796,5.72 22.9396,4.36 L22.9396,3 C22.9396,1.34 21.5996,0 19.9396,0 C18.2796,0 16.9396,1.34 16.9396,3 L16.9396,4.36 C11.2196,5.72 7.9396,10.84 7.9396,17 L7.9396,27 L3.9396,31 L3.9396,33 L35.9396,33 L35.9396,31 L31.9396,27 L31.9396,17 Z M19.9396,39 C22.1396,39 23.9396,37.2 23.9396,35 L15.9396,35 C15.9396,37.2 17.7396,39 19.9396,39 L19.9396,39 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
