import styling from "Services/styling";

export default {
	header: {
		position: "absolute",
		top: 30,
		left: 30,
		right: 30,
		textAlign: "center",
		...styling.mixins.padding(0, 30),
	},
	arrowLeft: {
		position: "absolute",
		left: 0,
		top: 0,
		cursor: "pointer",
		":hover path": {
			fill: styling.colors.primary,
		},
	},
	monthYear: {
		textAlign: "center",
	},
	arrowRight: {
		position: "absolute",
		right: 0,
		top: 0,
		cursor: "pointer",
		":hover path": {
			fill: styling.colors.primary,
		},
	},
	weekdays: {
		display: "flex",
		justifyContent: "space-between",
	},
	weekday: {
		width: `${100 / 7 - 1}%`,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: 36,
	},
	days: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-between",
	},
	day: (isSelected, isToday, isDisabled, isBlanker) => {
		let color = styling.colors.textQuintary;
		if (isSelected) {
			color = styling.colors.inputBackground;
		} else if (isToday) {
			color = styling.colors.primary;
		} else if (isDisabled) {
			color = styling.colors.inputDisabledText;
		}
		let hoverBackground = styling.colors.primaryFaded;
		if (isSelected) {
			hoverBackground = styling.colors.primary;
		} else if (isDisabled || isBlanker) {
			hoverBackground = styling.colors.inputBackground;
		}
		return {
			width: `${100 / 7 - 1}%`,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			height: 36,
			color,
			cursor: isDisabled || isSelected || isBlanker ? "default" : "pointer",
			backgroundColor: isSelected
				? styling.colors.primary
				: styling.colors.inputBackground,
			...styling.mixins.borderRadius(4),
			...styling.mixins.margin(0, 0, "1%"),
			...styling.mixins.transitionMulti(["color"], ["background-color"]),
			":hover": {
				backgroundColor: hoverBackground,
			},
		};
	},
};
