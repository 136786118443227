import { bool, func, string } from "prop-types";
import * as React from "react";

import { EMPTY_ARR } from "Services/constants/empty";
import { isPresent } from "Services/helper-utils";
import Styles from "./styles.css";

const emailRegex = new RegExp(
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

class InputEmail extends React.Component {
	static propTypes = {
		isDisabled: bool,
		isRequired: bool,
		isValid: bool,
		label: string,
		name: string,
		onChange: func,
		value: string,
	};
	constructor(props) {
		super(props);
		this.state = {
			errors: this.validate(props.value, props.isRequired),
			isTouched: false,
		};
	}
	input = null;
	validate = (value, isRequired) => {
		if (isRequired && !isPresent(value)) {
			return ["Required"];
		} else if (!emailRegex.test(value)) {
			return ["Invalid email"];
		}
		return EMPTY_ARR;
	};
	handleOnChange = (e) => {
		const { isDisabled, isRequired, name, onChange } = this.props;
		if (isDisabled) return;
		const errors = this.validate(e.target.value, isRequired);
		this.setState({
			errors,
		});
		onChange(name, e.target.value, errors.length === 0);
	};
	handleOnBlur = () => {
		if (!this.state.isTouched) {
			this.setState({
				isTouched: true,
			});
		}
	};
	render() {
		const { isDisabled, isRequired, label, name, value } = this.props;
		const { errors, isTouched } = this.state;
		return (
			<div css={Styles.wrapper(isDisabled)}>
				{isDisabled && <div css={Styles.inputCover} />}
				<input
					ref={(c) => {
						this.input = c;
					}}
					css={Styles.input(errors.length > 0, isTouched)}
					name={name}
					onBlur={this.handleOnBlur}
					onChange={this.handleOnChange}
					placeholder={isRequired ? `*${label}` : label}
					required={isRequired}
					tabIndex={1}
					type="email"
					value={value}
				/>
				{errors.length > 0 && (
					<div css={Styles.error(isTouched)}>{errors[0]}</div>
				)}
			</div>
		);
	}
}

export default InputEmail;
