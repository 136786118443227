import { EMPTY_ARR } from "Services/constants/empty";
import styling from "Services/styling";

export default {
	closeIcon: {
		height: 20,
		width: 20,
		position: "absolute",
		zIndex: 20,
		cursor: "pointer",
		top: 64,
		right: 40,
		":hover use": {
			fill: styling.colors.textTertiary,
			...styling.mixins.transition("fill"),
		},
	},
	stage: (inView) => ({
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		overflowX: "hidden",
		overflowY: "auto",
		backgroundColor: styling.colors.backgroundDefault,
		...styling.mixins.padding(120, 40),
		...styling.mixins.translate(`${inView ? 0 : "100%"}`, 0),
		...styling.mixins.transition("transform"),
	}),
	stageContent: {
		width: "100%",
		...styling.mixins.margin("auto", 0),
	},
	brand: {
		position: "absolute",
		left: 40,
		top: 70,
	},
	stageLink: {
		position: "relative",
		fontSize: "4rem",
		lineHeight: "4.4rem",
		cursor: "pointer",
		width: "100%",
		fontWeight: 700,
		color: styling.colors.textHeaderDefault,
		...styling.mixins.transition("color"),
		...styling.mixins.padding(0, 30, 0, 0),
		":hover": {
			color: styling.colors.linkInteract,
			"> span:before,> span:after": {
				backgroundColor: styling.colors.linkInteract,
			},
		},
	},
	stageLinkCaret: (top) => ({
		position: "absolute",
		right: 2,
		top,
		width: 12,
		":before,:after": {
			content: '""',
			position: "absolute",
			height: 2,
			backgroundColor: styling.colors.textHeaderDefault,
			width: 12,
			...styling.mixins.transition("background-color"),
		},
		":before": {
			...styling.mixins.rotate("45deg"),
			...styling.mixins.transformOrigin("100%", "100%"),
		},
		":after": {
			...styling.mixins.rotate("-45deg"),
			...styling.mixins.transformOrigin("100%", 0),
		},
	}),
	back: {
		position: "absolute",
		left: 46,
		top: 66,
		fontSize: "2rem",
		lineHeight: "2.4rem",
		width: "auto",
		color: styling.colors.textHeaderDefault,
		cursor: "pointer",
		...styling.mixins.padding(0, 0, 0, 38),
		...styling.mixins.transition("color"),
		":hover": {
			color: styling.colors.linkInteract,
		},
		":hover path": {
			fill: styling.colors.linkInteract,
		},
	},
	backArrow: {
		position: "absolute",
		left: 0,
		top: 2,
	},
	stageHeader: {
		position: "relative",
		fontSize: "4rem",
		lineHeight: "4.4rem",
		width: "100%",
		fontWeight: 700,
		color: styling.colors.textHeaderDefault,
		...styling.mixins.margin(0, 0, 22),
	},
	menuLink: {
		display: "block",
		fontSize: "2.4rem",
		fontWeight: 500,
		lineHeight: "2.9rem",
		color: styling.colors.textDefault,
		...styling.mixins.transition("color"),
		...styling.mixins.margin(11, 0),
		":hover, :focus, :active": {
			color: styling.colors.linkInteract,
		},
	},
	menuLinkActive: {
		color: styling.colors.linkInteract,
	},
	signInSection: {
		position: "relative",
		width: "100%",
		cursor: "pointer",
		...styling.mixins.padding(47, 0, 30),
		color: styling.colors.textDefault,
		":hover": {
			color: styling.colors.linkInteract,
			"> span:before,> span:after": {
				backgroundColor: styling.colors.linkInteract,
			},
		},
	},
	signInSeperator: {
		width: "100%",
		...styling.mixins.border(
			[1, "dashed", styling.colors.linkDefault],
			EMPTY_ARR,
			EMPTY_ARR
		),
		...styling.mixins.margin(32, 0, 1),
	},
	signInIcon: {
		height: 40,
		width: 40,
		...styling.mixins.margin(0, 0, 7),
	},
	signInTitle: {
		fontSize: "2.4rem",
		lineHeight: "2.9rem",
		fontWeight: 500,
		...styling.mixins.margin(0, 0, 9),
		...styling.mixins.transition("color"),
	},
	strapText: {
		fontSize: "1.6rem",
		lineHeight: "2.4rem",
		color: styling.colors.textQuadrinary,
	},
	inputLabel: {
		fontSize: "1.6rem",
		lineHeight: "1.9rem",
		color: styling.colors.textDefault,
		...styling.mixins.margin(36, 0, 11),
	},
};
