import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 40 40">
		<path
			d="M38,8.0004 L34,8.0004 L34,26.0004 L8,26.0004 L8,30.0004 C8,31.1004 8.9,32.0004 10,32.0004 L32,32.0004 L40,40.0004 L40,10.0004 C40,8.9004 39.1,8.0004 38,8.0004"
			fill={styling.colors.primary}
		/>
		<path
			d="M5.1796,19.18 L3.9996,20.34 L3.9996,4 L25.9996,4 L25.9996,18 L6.3396,18 L5.1796,19.18 Z M27.9996,22 C29.0996,22 29.9996,21.1 29.9996,20 L29.9996,2 C29.9996,0.9 29.0996,0 27.9996,0 L1.9996,0 C0.8996,0 -0.0004,0.9 -0.0004,2 L-0.0004,30 L7.9996,22 L27.9996,22 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
