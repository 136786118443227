import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 70 44">
		<path
			d="M52,4 C42.06,4 34,12.058 34,22 C34,31.94 42.04,40 52,40 C61.94,40 70,31.94 70,22 C70,12.058 61.94,4 52,4"
			fill={styling.colors.primary}
		/>
		<path
			d="M24,0 L24,4.12 C32.34,5.04 38.96,11.66 39.88,20 L39.88,20 L44,20 L44,24 L39.88,24 C38.96,32.34 32.34,38.96 24,39.88 L24,39.88 L24,44 L20,44 L20,39.88 C11.66,38.96 5.04,32.34 4.12,24 L4.12,24 L0,24 L0,20 L4.12,20 C5.04,11.66 11.66,5.04 20,4.12 L20,4.12 L20,0 L24,0 Z M22,8 C14.26,8 8,14.26 8,22 C8,29.74 14.26,36 22,36 C29.74,36 36,29.74 36,22 C36,14.26 29.74,8 22,8 Z M22,14 C26.42,14 30,17.58 30,22 C30,26.42 26.42,30 22,30 C17.58,30 14,26.42 14,22 C14,17.58 17.58,14 22,14 Z M22,18 C19.8,18 18,19.8 18,22 C18,24.2 19.8,26 22,26 C24.2,26 26,24.2 26,22 C26,19.8 24.2,18 22,18 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
