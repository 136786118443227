import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 45 19">
		<path
			d="M36,1 C33.6,1 31.3333333,1.93333333 29.7166667,3.55 L18,13.95 C16.9333333,15.0166667 15.5166667,15.6 14,15.6 C10.8833333,15.6 8.35,13.0833333 8.35,9.96666667 C8.35,6.85 10.8833333,4.33333333 14,4.33333333 C15.5166667,4.33333333 16.9333333,4.91666667 18.0666667,6.05 L19.95,7.71666667 L22.4666667,5.48166667 L20.3666667,3.63333333 C18.6666667,1.93333333 16.4,1 14,1 C9.03333333,1 5,5.03333333 5,9.96666667 C5,14.9 9.03333333,18.9333333 14,18.9333333 C16.4,18.9333333 18.6666667,18 20.2833333,16.3816667 L32,5.98333333 C33.0666667,4.91666667 34.4833333,4.33333333 36,4.33333333 C39.1166667,4.33333333 41.65,6.85 41.65,9.96666667 C41.65,13.0833333 39.1166667,15.6 36,15.6 C34.5,15.6 33.0666667,15.0166667 31.9333333,13.8816667 L30.0333333,12.2 L27.5166667,14.4333333 L29.6333333,16.3 C31.3333333,17.9816667 33.5833333,18.9166667 36,18.9166667 C40.9666667,18.9166667 45,14.9 45,9.95 C45,4.99833333 40.9666667,1 36,1"
			fill={styling.colors.primary}
		/>
		<path
			d="M31,-4.28101998e-13 C28.6,-4.28101998e-13 26.3333333,0.933333333 24.7166667,2.55 L13,12.95 C11.9333333,14.0166667 10.5166667,14.6 9,14.6 C5.88333333,14.6 3.35,12.0833333 3.35,8.96666667 C3.35,5.85 5.88333333,3.33333333 9,3.33333333 C10.5166667,3.33333333 11.9333333,3.91666667 13.0666667,5.05 L14.95,6.71666667 L17.4666667,4.48166667 L15.3666667,2.63333333 C13.6666667,0.933333333 11.4,-4.28101998e-13 9,-4.28101998e-13 C4.03333333,-4.28101998e-13 0,4.03333333 0,8.96666667 C0,13.9 4.03333333,17.9333333 9,17.9333333 C11.4,17.9333333 13.6666667,17 15.2833333,15.3816667 L27,4.98333333 C28.0666667,3.91666667 29.4833333,3.33333333 31,3.33333333 C34.1166667,3.33333333 36.65,5.85 36.65,8.96666667 C36.65,12.0833333 34.1166667,14.6 31,14.6 C29.5,14.6 28.0666667,14.0166667 26.9333333,12.8816667 L25.0333333,11.2 L22.5166667,13.4333333 L24.6333333,15.3 C26.3333333,16.9816667 28.5833333,17.9166667 31,17.9166667 C35.9666667,17.9166667 40,13.9 40,8.95 C40,3.99833333 35.9666667,-4.28101998e-13 31,-4.28101998e-13"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
