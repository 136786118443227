import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 46 42">
		<rect fill={styling.colors.primary} x="30" y="24" width="16" height="18" />
		<rect fill={styling.colors.primary} x="30" y="4" width="16" height="18" />
		<rect fill={styling.colors.primary} x="4" y="4" width="16" height="18" />
		<path
			d="M26,0 L26,6 L14.002,6 L14.002,0 L0,0 L0,16 L14.002,16 L14.002,10 L18,10 L18,30 L26,30 L26,36 L40,36 L40,20 L26,20 L26,26 L22,26 L22,10 L26,10 L26,16 L40,16 L40,0 L26,0 Z M30.002,12.002 L36.002,12.002 L36.002,4 L30.002,4 L30.002,12.002 Z M4,12.002 L10.002,12.002 L10.002,4 L4,4 L4,12.002 Z M30.002,32.002 L36.002,32.002 L36.002,24 L30.002,24 L30.002,32.002 Z"
			fill={styling.colors.iconSecondary}
			fillRule="evenodd"
		/>
	</svg>
);
