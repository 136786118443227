import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 158 28">
		<polygon
			fill={styling.colors.primary}
			points="106.7835 9.76956682 96 10.6670877 104.1885 17.6394547 101.7285 28 111 22.5028686 120.27 28 117.825 17.6394547 126 10.6670877 115.2135 9.75335544 111 0"
		/>
		<polygon
			fill={styling.colors.primary}
			points="74.7835 9.76956682 64 10.6670877 72.1885 17.6394547 69.7285 28 79 22.5028686 88.27 28 85.825 17.6394547 94 10.6670877 83.2135 9.75335544 79 0"
		/>
		<polygon
			fill={styling.colors.primary}
			points="42.7835 9.76956682 32 10.6670877 40.1885 17.6394547 37.7285 28 47 22.5028686 56.27 28 53.825 17.6394547 62 10.6670877 51.2135 9.75335544 47 0"
		/>
		<polygon
			fill={styling.colors.primary}
			points="10.7835 9.76956682 0 10.6670877 8.1885 17.6394547 5.7285 28 15 22.5028686 24.27 28 21.825 17.6394547 30 10.6670877 19.2135 9.75335544 15 0"
		/>
		<path
			d="M143,0 L138.785,9.77052632 L128,10.6694737 L136.19,17.64 L133.7315,28 L143,22.5031579 L152.27,28 L149.825,17.64 L158,10.6694737 L147.215,9.75431579 L143,0 Z M138.8615,16.7852632 L133.88,12.5410526 L140.45,11.9825263 L143,6.04210526 L145.565,11.9957895 L152.135,12.5557895 L147.155,16.7985263 L148.655,23.1073684 L143,19.7473684 L137.36,23.0926316 L138.8615,16.7852632 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
