import { object, node, number } from "prop-types";
import * as React from "react";

import { EMPTY_OBJ } from "Services/constants/empty";
import styling from "Services/styling";
import withDisplayAware from "Services/withDisplayAware";

const minHeightClass = (minHeight, style) => ({
	minHeight,
	position: "relative",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	...styling.mixins.padding(60, 0),
	"& > *": {
		width: "auto",
	},
	...style,
});

const MinPageHeightWrapper = ({ clientHeight, children, style }) => (
	<section css={minHeightClass(clientHeight, style)}>{children}</section>
);

MinPageHeightWrapper.propTypes = {
	children: node,
	clientHeight: number,
	style: object,
};

MinPageHeightWrapper.defaultProps = {
	style: EMPTY_OBJ,
};

export default withDisplayAware("clientHeight")(MinPageHeightWrapper);
