import pick from "lodash.pick";
import throttle from "lodash.throttle";
import * as React from "react";
import { withController } from "react-scroll-parallax";

import {
	getClientHeight,
	getClientWidth,
	getScrollHeight,
	getScrollTop,
} from "Services/display-utils";
import getDisplayName from "Services/getDisplayName";
import styling from "Services/styling";

const withDisplayAware = (...listenedProps) => (WrappedComponent) =>
	withController(
		class extends React.Component {
			static displayName = `withDisplayAware(${getDisplayName(
				WrappedComponent
			)})`;
			constructor(props) {
				super(props);
				this.state = {
					clientHeight: 0,
					clientWidth: 0,
					scrollHeight: 0,
					scrollTop: 0,
					size: "xlarge",
				};
			}
			componentDidMount() {
				if (typeof window !== "undefined") {
					window.addEventListener("resize", this.throttledScrollCheck);
					window.document.addEventListener("scroll", this.throttledScrollCheck);
					this.throttledScrollCheck();
				}
			}
			componentWillUnmount() {
				if (typeof window !== "undefined") {
					window.removeEventListener("resize", this.throttledScrollCheck);
					window.document.removeEventListener(
						"scroll",
						this.throttledScrollCheck
					);
				}
			}
			getSize = (clientWidth) => {
				let size = "xlarge";
				if (clientWidth < styling.sizes.breakpoints.breakpointSmall) {
					size = "small";
				} else if (clientWidth < styling.sizes.breakpoints.breakpointMedium) {
					size = "medium";
				} else if (clientWidth < styling.sizes.breakpoints.breakpointLarge) {
					size = "large";
				}
				return size;
			};
			throttledScrollCheck = throttle(() => {
				const clientWidth = getClientWidth();
				const size = this.getSize(clientWidth);
				const clientHeight = getClientHeight();
				const shouldUpdate = this.state.clientHeight !== clientHeight;
				this.setState(
					{
						clientHeight,
						clientWidth,
						scrollHeight: getScrollHeight(),
						scrollTop: getScrollTop(),
						size,
					},
					() => {
						if (shouldUpdate) {
							this.props.parallaxController.update();
						}
					}
				);
			}, 100);
			render() {
				const state = this.state;
				return (
					<WrappedComponent {...pick(state, listenedProps)} {...this.props} />
				);
			}
		}
	);

export default withDisplayAware;
