import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 70 36">
		<path
			d="M48,0 L26,12 L34,16.36 L34,28.36 L48,36 L62,28.36 L62,16.36 L66,14.18 L66,28 L70,28 L70,12 L48,0 Z M34.36,12 L48,4.562 L61.64,12 L48,19.44 L34.36,12 Z M58,18.538 L58,25.982 L48,31.438 L38,25.982 L38,18.538 L48,24 L58,18.538 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M19,8 L16,8 L16,20 L25.5,25.7 L27,23.238 L19,18.5 L19,8 Z M18,32 C10.26,32 4,25.738 4,18 C4,10.26 10.26,4 18,4 C25.74,4 32,10.26 32,18 C32,25.738 25.74,32 18,32 L18,32 Z M18,0 C8.06,0 0,8.058 0,18 C0,27.94 8.04,36 18,36 C27.94,36 36,27.94 36,18 C36,8.058 27.94,0 18,0 L18,0 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
