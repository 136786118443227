import { bool, func } from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { CallToActionPropTypes } from "~/types";
import Form from "Components/Form";
import { Messages } from "Components/Icons";
import InputEmail from "Components/InputEmail";
import InputHoneypot from "Components/InputHoneypot";
import InputPhone from "Components/InputPhone";
import InputText from "Components/InputText";
import InputTextarea from "Components/InputTextarea";
import flaikRequest from "Services/flaik-request";
import Styles from "./styles.css";

class ModalDemo extends React.Component {
	static propTypes = {
		...CallToActionPropTypes,
		modalClose: func,
		hasFormResponse: bool,
		reset: func,
	};
	constructor(props) {
		super(props);
		this.state = {
			isSubmitting: false,
			fullnameValue: "",
			fullnameIsValid: false,
			jobTitleValue: "",
			jobTitleIsValid: false,
			resortNameValue: "",
			resortNameIsValid: false,
			emailValue: "",
			emailIsValid: false,
			telephoneValue: "",
			telephoneIsValid: false,
			messageValue: "",
			hpottyValue: "",
		};
	}
	resetAllFields = () => {
		this.setState({
			isSubmitting: false,
			firstnameValue: "",
			firstnameIsValid: false,
			lastnameValue: "",
			lastnameIsValid: false,
			jobTitleValue: "",
			jobTitleIsValid: false,
			resortNameValue: "",
			resortNameIsValid: false,
			emailValue: "",
			emailIsValid: false,
			telephoneValue: "",
			telephoneIsValid: false,
			messageValue: "",
			hpottyValue: "",
		});
	};
	submitForm = (additionalData) => {
		const {
			firstnameValue,
			lastnameValue,
			emailValue,
			resortNameValue,
			jobTitleValue,
			telephoneValue,
			messageValue,
			hpottyValue,
		} = this.state;
		// request demo ajax
		return flaikRequest({
			data: {
				flaikDemo: {
					firstName: firstnameValue,
					lastName: lastnameValue,
					email: emailValue,
					resortName: resortNameValue,
					jobTitle: jobTitleValue,
					phoneNumber: telephoneValue,
					message: messageValue,
					honey: hpottyValue,
					...additionalData,
				},
			},
		});
	};
	updateFieldValue = (fieldName, fieldValue, fieldIsValid) => {
		const { hasFormResponse, reset } = this.props;
		if (hasFormResponse) {
			reset();
		}
		this.setState({
			[`${fieldName}Value`]: fieldValue,
			[`${fieldName}IsValid`]: fieldIsValid,
		});
	};
	render() {
		const { buttonText, title, thankYou } = this.props;
		const {
			emailIsValid,
			emailValue,
			firstnameIsValid,
			firstnameValue,
			lastnameIsValid,
			lastnameValue,
			jobTitleValue,
			jobTitleIsValid,
			resortNameValue,
			resortNameIsValid,
			telephoneIsValid,
			telephoneValue,
			messageValue,
			hpottyValue,
		} = this.state;
		return (
			<>
				<div css={Styles.icon}>
					<Messages />
				</div>
				<div css={Styles.title}>{title}</div>
				<Form
					hasCaptcha
					isValid={
						emailIsValid &&
						firstnameIsValid &&
						lastnameIsValid &&
						telephoneIsValid &&
						jobTitleIsValid &&
						resortNameIsValid
					}
					onSuccess={this.resetAllFields}
					requestDemo={this.submitForm}
					renderFields={({ isSubmitting }) => (
						<>
							<InputHoneypot
								isDisabled={isSubmitting}
								label="Leave me blank"
								name="hpotty"
								onChange={this.updateFieldValue}
								value={hpottyValue}
							/>
							<InputText
								isDisabled={isSubmitting}
								isRequired
								isValid={firstnameIsValid}
								label="First name"
								name="firstname"
								onChange={this.updateFieldValue}
								value={firstnameValue}
							/>
							<InputText
								isDisabled={isSubmitting}
								isRequired
								isValid={lastnameIsValid}
								label="Last name"
								name="lastname"
								onChange={this.updateFieldValue}
								value={lastnameValue}
							/>
							<InputText
								isDisabled={isSubmitting}
								isRequired
								isValid={jobTitleIsValid}
								label="Job Title"
								name="jobTitle"
								onChange={this.updateFieldValue}
								value={jobTitleValue}
							/>
							<InputText
								isDisabled={isSubmitting}
								isRequired
								isValid={resortNameIsValid}
								label="Resort you work at"
								name="resortName"
								onChange={this.updateFieldValue}
								value={resortNameValue}
							/>
							<InputPhone
								isDisabled={isSubmitting}
								isRequired
								isValid={telephoneIsValid}
								label="Phone number"
								name="telephone"
								onChange={this.updateFieldValue}
								value={telephoneValue}
							/>
							<InputEmail
								isDisabled={isSubmitting}
								isRequired
								isValid={emailIsValid}
								label="Email"
								name="email"
								onChange={this.updateFieldValue}
								value={emailValue}
							/>
							<InputTextarea
								isDisabled={isSubmitting}
								label="Message"
								name="message"
								onChange={this.updateFieldValue}
								value={messageValue}
							/>
						</>
					)}
					submitText={buttonText}
					successMessage={thankYou}
					type={"requestDemo"}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	hasFormResponse: state.formResponse.key !== "",
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	reset: () =>
		dispatch({
			type: "FORM_SUBMIT_RESET",
			payload: {
				formKey: null,
			},
		}),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ModalDemo);
