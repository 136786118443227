import { useStaticQuery, graphql } from "gatsby";
import { string } from "prop-types";
import React from "react";
import Helmet from "react-helmet";

import appleTouch57 from "~/images/favicons/apple-touch-icon-57x57.png";
import appleTouch60 from "~/images/favicons/apple-touch-icon-60x60.png";
import appleTouch72 from "~/images/favicons/apple-touch-icon-72x72.png";
import appleTouch76 from "~/images/favicons/apple-touch-icon-76x76.png";
import appleTouch114 from "~/images/favicons/apple-touch-icon-114x114.png";
import appleTouch120 from "~/images/favicons/apple-touch-icon-120x120.png";
import appleTouch144 from "~/images/favicons/apple-touch-icon-144x144.png";
import appleTouch152 from "~/images/favicons/apple-touch-icon-152x152.png";
import appleTouch180 from "~/images/favicons/apple-touch-icon-180x180.png";
import favicon194 from "~/images/favicons/favicon-194x194.png";
import favicon32 from "~/images/favicons/favicon-32x32.png";
import favicon16 from "~/images/favicons/favicon-16x16.png";
import faviconIco from "~/images/favicons/favicon.ico";
import androidChrome192 from "~/images/favicons/android-chrome-192x192.png";
import mstile144 from "~/images/favicons/mstile-144x144.png";
import safariPinnedTab from "~/images/favicons/safari-pinned-tab.svg";

function SEO({ description, lang, meta, title }) {
	const { site, share } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						siteUrl
					}
				}
				share: file(name: { eq: "flaik_share" }) {
					childImageSharp {
						original {
							src
						}
					}
				}
			}
		`
	);
	const metaDescription = description || site.siteMetadata.description;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			defaultTitle={`${site.siteMetadata.title}`}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
			]}>
			<title>{title}</title>
			<base href="/" />
			<meta charSet="utf-8" />
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1.0, maximum-scale=2.0, shrink-to-fit=no"
			/>
			<meta property="fb:app_id" content="2404039473169040" />
			<meta property="og:type" content="website" />
			<meta property="og:site_name" content={`${site.siteMetadata.title}`} />
			<meta property="og:language" content={`${lang}`} />
			{share && (
				<meta
					property="og:image"
					content={`${site.siteMetadata.siteUrl}${share.childImageSharp.original.src}`}
				/>
			)}
			<meta name="twitter:card" content="summary_large_image" />

			<link rel="apple-touch-icon" sizes="57x57" href={appleTouch57} />
			<link rel="apple-touch-icon" sizes="60x60" href={appleTouch60} />
			<link rel="apple-touch-icon" sizes="72x72" href={appleTouch72} />
			<link rel="apple-touch-icon" sizes="76x76" href={appleTouch76} />
			<link rel="apple-touch-icon" sizes="114x114" href={appleTouch114} />
			<link rel="apple-touch-icon" sizes="120x120" href={appleTouch120} />
			<link rel="apple-touch-icon" sizes="144x144" href={appleTouch144} />
			<link rel="apple-touch-icon" sizes="152x152" href={appleTouch152} />
			<link rel="apple-touch-icon" sizes="180x180" href={appleTouch180} />
			<link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
			<link rel="icon" type="image/png" sizes="194x194" href={favicon194} />
			<link
				rel="icon"
				type="image/png"
				sizes="192x192"
				href={androidChrome192}
			/>
			<link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
			<link rel="mask-icon" href={safariPinnedTab} color="#14181c" />
			<link rel="shortcut icon" href={faviconIco} />

			<meta name="msapplication-TileColor" content="#14181c" />
			<meta name="msapplication-TileImage" content={mstile144} />
			<meta name="msapplication-config" content="/browserconfig.xml" />
		</Helmet>
	);
}

SEO.defaultProps = {
	lang: `en`,
	description: ``,
};

SEO.propTypes = {
	description: string,
	lang: string,
	title: string.isRequired,
};

export default SEO;
