import { bool, string, exact, object, func, oneOfType } from "prop-types";
import * as React from "react";
import { connect } from "react-redux";

import { EMPTY_ARR } from "Services/constants/empty";
import { isFunction } from "Services/helper-utils";
import styling from "Services/styling";

const messageStyle = (isComplete) => {
	const style = {
		fontSize: "1.2rem",
		lineHeight: "2.2rem",
		textAlign: "left",
		color: styling.colors.primary,
		...styling.mixins.borderRadius(4),
		...styling.mixins.border([1, "solid", styling.colors.primary]),
		...styling.mixins.margin(10, 0),
		...styling.mixins.padding(10),
	};
	if (isComplete) {
		return {
			...style,
			position: "absolute",
			top: 0,
			bottom: 0,
			left: 0,
			zIndex: 2,
			right: 0,
			color: styling.colors.textDefault,
			backgroundColor: styling.colors.backgroundDefault,
			display: "flex",
			alignItems: "center",
			textAlign: "center",
			justifyContent: "center",
			flexDirection: "column",
			fontSize: "2.4rem",
			lineHeight: "3rem",
			...styling.mixins.borderRadius(0),
			...styling.mixins.border(EMPTY_ARR),
			...styling.mixins.margin(0),
		};
	}
	return style;
};

const FormMessage = ({ successMessage, isComplete, type, formResponse }) => {
	if (type !== formResponse.key && !isComplete) {
		return null;
	}
	let message = `${formResponse.response.msg}`;
	if (isComplete) {
		message = successMessage;
	}
	if (isFunction(message)) {
		return <div css={messageStyle(isComplete)}>{message()}</div>;
	}
	return (
		<div
			css={messageStyle(isComplete)}
			dangerouslySetInnerHTML={{ __html: message }}
		/>
	);
};

FormMessage.propTypes = {
	isComplete: bool,
	successMessage: oneOfType([string, func]),
	type: string,
	formResponse: exact({
		key: string,
		response: object,
	}),
};

const mapStateToProps = (state, ownProps) => ({
	isComplete: state.completedForms.includes(ownProps.type),
	formResponse: state.formResponse,
});

export default connect(mapStateToProps)(FormMessage);
