import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 40 40">
		<path
			d="M9,14.5 C9,15.8825 7.656,17 6,17 C4.344,17 3,15.8825 3,14.5 L3,9.5 C3,8.1175 4.344,7 6,7 C7.656,7 9,8.1175 9,9.5 L9,14.5 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M23,32.5 C23,33.8825 21.656,35 20,35 C18.344,35 17,33.8825 17,32.5 L17,27.5 C17,26.1175 18.344,25 20,25 C21.656,25 23,26.1175 23,27.5 L23,32.5 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M37,18.5 C37,19.8825 35.656,21 34,21 C32.344,21 31,19.8825 31,18.5 L31,13.5 C31,12.1175 32.344,11 34,11 C35.656,11 37,12.1175 37,13.5 L37,18.5 Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M8,0 L8,4.37 C10.2470968,5.17129032 11.8910718,7.25561915 11.9947964,9.74929979 L12,10 L12,14 C12,16.52 10.428512,18.6597711 8.22266456,19.5456265 L8,19.63 L8,40 L4,40 L4,19.63 C1.75290323,18.8287097 0.1089282,16.7443809 0.00520358498,14.2507002 L0,14 L0,10 C0,7.48 1.57148803,5.34022893 3.77733544,4.45437347 L4,4.37 L4,0 L8,0 Z M22,0 L22,22.37 C24.2470968,23.1712903 25.8910718,25.2556191 25.9947964,27.7492998 L26,28 L26,32 C26,34.52 24.428512,36.6597711 22.2226646,37.5456265 L22,37.63 L22,40 L18,40 L18,37.63 C15.7529032,36.8287097 14.1089282,34.7443809 14.0052036,32.2507002 L14,32 L14,28 C14,25.48 15.571488,23.3402289 17.7773354,22.4543735 L18,22.37 L18,0 L22,0 Z M36,0 L36,8.37 C38.322,9.198 40,11.396 40,14 L40,14 L40,18 C40,20.604 38.322,22.802 36,23.63 L36,23.63 L36,40 L32,40 L32,23.63 C29.678,22.802 28,20.604 28,18 L28,18 L28,14 C28,11.396 29.678,9.198 32,8.37 L32,8.37 L32,0 L36,0 Z M20,26 C18.9461818,26 18.0818843,26.8145744 18.0054891,27.8505599 L18,28 L18,32 C18,33.106 18.896,34 20,34 C21.0538182,34 21.9181157,33.1854256 21.9945109,32.1494401 L22,32 L22,28 C22,26.894 21.104,26 20,26 Z M34,12 C32.896,12 32,12.894 32,14 L32,14 L32,18 C32,19.106 32.896,20 34,20 C35.104,20 36,19.106 36,18 L36,18 L36,14 C36,12.894 35.104,12 34,12 Z M6,8 C4.94618182,8 4.0818843,8.81457438 4.00548911,9.85055992 L4,10 L4,14 C4,15.106 4.896,16 6,16 C7.05381818,16 7.9181157,15.1854256 7.99451089,14.1494401 L8,14 L8,10 C8,8.894 7.104,8 6,8 Z"
			fill={styling.colors.iconSecondary}
		/>
	</svg>
);
