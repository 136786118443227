import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 54 40">
		<g id="maps" transform="translate(0.000000, -1.000000)">
			<path
				d="M53.5333333,0.955555556 L41,6 L28,1 L13.8,5.22222222 C13.3333333,5.37777778 13,5.77777778 13,6.28888889 L13,39.8888889 C13,40.5111111 13.4888889,41 14.1111111,41 L14.4666667,40.9333333 L27.3333333,36.3333333 L40,40 L54,36 L53.5333333,0.955555556 Z"
				fill={styling.colors.primary}
				transform="translate(33.500000, 20.977778) scale(-1, 1) translate(-33.500000, -20.977778) "
			/>
			<path
				d="M35.5555556,33.3111111 L28.8888889,35.5555556 L28.8888889,9.57777778 L35.5555556,7 L35.5555556,33.3111111 Z M24.4444444,35.5111111 L15.5555556,32.4 L15.5555556,6.48888889 L24.4444444,9.6 L24.4444444,35.5111111 Z M11.1111111,32.4444444 L4.44444444,35.0244444 L4.44444444,8.68888889 L11.1111111,6.44444444 L11.1111111,32.4444444 Z M38.5333333,1.06666667 L26.6666667,5.66666667 L13.3333333,1 L0.8,5.22222222 C0.333333333,5.37777778 0,5.77777778 0,6.28888889 L0,39.8888889 C0,40.5111111 0.488888889,41 1.11111111,41 L1.46666667,40.9333333 L13.3333333,36.3333333 L26.6666667,41 L39.2,36.7777778 C39.6666667,36.6222222 40,36.2222222 40,35.7111111 L40,2.11111111 C40,1.48888889 39.5111111,1 38.8888889,1 L38.5333333,1.06666667 Z"
				id="Fill-1"
				fill={styling.colors.iconSecondary}
			/>
		</g>
	</svg>
);
