import format from "date-fns/format";
import React from "react";
import { connect } from "react-redux";

import Styles from "./styles.css";

const ReliveYourDaySuccess = ({ response, reset }) => {
	const { activities } = response;
	// check number of activities
	// if 1, just send the user to that url
	// if more than one list them and let the user choose
	if (typeof activities === "undefined") {
		return null;
	}
	if (activities.length === 1 && typeof window !== "undefined") {
		window.location.href = activities[0].destinationUrl;
		return (
			<div css={Styles.wrapper}>Redirecting you to relive your day...</div>
		);
	}
	return (
		<div css={Styles.wrapper}>
			<p>
				More than one activity was found on the selected date. Please select
				which of the following sessions you would like to view:
			</p>
			{activities.map((activity) => (
				<a
					key={activity.startLocal}
					css={Styles.activity}
					href={activity.destinationUrl}>
					{format(new Date(activity.startLocal), "h:mma")} -{" "}
					{format(new Date(activity.endLocal), "h:mma")}
				</a>
			))}
			<div css={Styles.reset} onClick={reset}>
				Change details
			</div>
		</div>
	);
};

const mapStateToProps = (store) => ({
	response: store.formResponse.response,
});

const mapDispatchToProps = (dispatch) => ({
	reset: () =>
		dispatch({
			type: "FORM_SUBMIT_RESET",
			payload: {
				formKey: "reliveMyDay",
			},
		}),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReliveYourDaySuccess);
